import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import App from './App'
import { getChakraTheme } from './chakraTheme/theme'
import 'intersection-observer'
import './assets/fonts/fonts.css'
import { useThemeStore } from './stores/themeStore'

const ThemeWrapper = () => {
    const { theme } = useThemeStore()
    const chakraTheme = React.useMemo(() => getChakraTheme(theme), [theme])

    return (
        <ChakraProvider theme={chakraTheme}>
            <App />
        </ChakraProvider>
    )
}

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(<ThemeWrapper />)
