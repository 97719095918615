import { useNavigate, useLocation } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { YEARS } from '../constants/strings'
import useAuthStore from '../stores/authStore'
import useMovieStore from '../stores/movieStore'

const useHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const toast = useToast()

    const handleLogout = useAuthStore(state => state.handleLogout)
    const username = useAuthStore(state => state.username)

    const movies = useMovieStore(state => state.movies)
    const setMovies = useMovieStore(state => state.setMovies)

    const handleNavigation = (path: string) => {
        if (location.pathname !== path) {
            navigate(path)
        }
    }

    return {
        location,
        toast,
        navigate,
        handleNavigation,
        YEARS,
        username,
        handleLogout,
        movies,
        setMovies
    }
}

export default useHeader
