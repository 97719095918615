import { Tooltip, Box, Text, useBreakpointValue } from '@chakra-ui/react'
import MovieRatings from './MovieRatings'
import { calculateAverageRating, getRatingIcon, getRatingText } from '../Movie/utils/ratings'
import { useMovieTooltipStyles } from '../../constants/styles/movieTooltipStyles'

export interface Rating {
    Source: string
    Value: string
    _id: string
}

interface MovieListTooltipProps {
    title: string
    description?: string
    children: React.ReactNode
    ratings: Rating[]
}

const MovieListTooltip = ({ title, description, children, ratings }: MovieListTooltipProps) => {
    const averageRating = calculateAverageRating(ratings)
    const ratingIcon = getRatingIcon(averageRating)
    const { tooltipProps, tooltipBoxProps, titleTextProps, descriptionTextProps } =
        useMovieTooltipStyles()

    const isMobile = useBreakpointValue({ base: true, md: false })

    if (isMobile) return <>{children}</>

    return (
        <Tooltip
            label={
                <Box {...tooltipBoxProps}>
                    <Text {...titleTextProps}>{title}</Text>
                    <Text {...descriptionTextProps}>
                        {description === 'N/A' || !description
                            ? 'Sin descripción disponible'
                            : description}
                    </Text>
                    <MovieRatings ratings={ratings} />
                    {ratingIcon && (
                        <Tooltip label={getRatingText(averageRating)} hasArrow>
                            <Box position="absolute" bottom="8px" right="8px" cursor="pointer">
                                {ratingIcon}
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            }
            {...tooltipProps}
        >
            {children}
        </Tooltip>
    )
}

export default MovieListTooltip
