import { useEffect, useMemo } from 'react'
import { Movie } from '../interfaces/movie'
import { cleanTitle } from '../utils/cleanMovieTitle'

interface UseFilteredMoviesProps {
    movies: Movie[]
    searchQuery: string
    setDisplayedMovies: (movies: Movie[]) => void
    movieDetails: any
}

const useFilteredMovies = ({
    movies,
    searchQuery,
    setDisplayedMovies,
    movieDetails
}: UseFilteredMoviesProps) => {
    const filteredMovies = useMemo(() => {
        return movies
            .map((movie: Movie) => {
                const detailsKey = `${cleanTitle(movie.title)}_${movie.year}_details`
                const movieExtraDetails = movieDetails[detailsKey] || {}

                return { ...movie, ...movieExtraDetails }
            })
            .filter((movie: Movie) =>
                searchQuery ? movie.title.toLowerCase().includes(searchQuery.toLowerCase()) : true
            )
            .sort((a, b) => {
                const dateA = new Date(a.lastModified).getTime()
                const dateB = new Date(b.lastModified).getTime()
                return dateB - dateA
            })
    }, [movies, searchQuery, movieDetails])

    const isNewMovie = (lastModified: string): boolean => {
        const oneWeekAgo = new Date()
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

        return new Date(lastModified) > oneWeekAgo
    }

    useEffect(() => {
        setDisplayedMovies(filteredMovies)
    }, [setDisplayedMovies, filteredMovies, movies])

    return {
        filteredMovies,
        isNewMovie
    }
}

export default useFilteredMovies
