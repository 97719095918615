import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useTheme,
    IconButton,
    HStack,
    Heading,
    Tooltip
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { fetchActivityLogs } from '../../api/adminService'
import { FiRefreshCw } from 'react-icons/fi'

const AdminActivityLogs = () => {
    const [logs, setLogs] = useState([])
    const theme = useTheme()

    const refreshLogs = async () => {
        const data = await fetchActivityLogs()
        setLogs(data)
    }

    useEffect(() => {
        refreshLogs()
    }, [])

    return (
        <Box>
            <HStack justify="space-between" mb={3}>
                <Heading size="md" color={theme.colors.brand.accent}>
                    Actividad reciente
                </Heading>
                <Tooltip label="Actualizar" hasArrow>
                    <IconButton
                        aria-label="Actualizar"
                        icon={<FiRefreshCw />}
                        size="sm"
                        variant="outline"
                        borderColor={theme.colors.brand.accent}
                        color={theme.colors.brand.accent}
                        _hover={{
                            bg: theme.colors.brand.accent,
                            color: theme.colors.brand.background
                        }}
                        onClick={refreshLogs}
                    />
                </Tooltip>
            </HStack>

            <Table
                variant="simple"
                bg={theme.colors.brand.card}
                borderRadius="lg"
                boxShadow="md"
                width="100%"
                sx={{
                    '@media screen and (max-width: 768px)': {
                        display: 'block'
                    }
                }}
            >
                <Thead
                    sx={{
                        '@media screen and (max-width: 768px)': {
                            display: 'none'
                        }
                    }}
                >
                    <Tr>
                        <Th color={theme.colors.brand.accent}>Usuario</Th>
                        <Th color={theme.colors.brand.accent}>Acción</Th>
                        <Th color={theme.colors.brand.accent}>Película</Th>
                        <Th color={theme.colors.brand.accent}>IP</Th>
                        <Th color={theme.colors.brand.accent}>Fecha</Th>
                    </Tr>
                </Thead>

                <Tbody
                    sx={{
                        '@media screen and (max-width: 768px)': {
                            display: 'block'
                        }
                    }}
                >
                    {logs.map((log: any) => (
                        <Tr
                            key={log._id}
                            _hover={{ bg: theme.colors.brand.header, transition: '0.2s' }}
                            sx={{
                                '@media screen and (max-width: 768px)': {
                                    display: 'block',
                                    borderBottom: '1px solid',
                                    borderColor: theme.colors.brand.border,
                                    padding: '1rem'
                                }
                            }}
                        >
                            <Td
                                sx={{
                                    '@media screen and (max-width: 768px)': {
                                        display: 'block',
                                        fontWeight: 'bold',
                                        paddingBottom: '0.25rem',
                                        _before: {
                                            content: '"Usuario: "',
                                            fontWeight: 'semibold'
                                        }
                                    }
                                }}
                            >
                                {log.userId?.username}
                            </Td>
                            <Td
                                sx={{
                                    '@media screen and (max-width: 768px)': {
                                        display: 'block',
                                        paddingBottom: '0.25rem',
                                        _before: {
                                            content: '"Acción: "',
                                            fontWeight: 'semibold'
                                        }
                                    }
                                }}
                            >
                                {log.action}
                            </Td>
                            <Td
                                sx={{
                                    '@media screen and (max-width: 768px)': {
                                        display: 'block',
                                        paddingBottom: '0.25rem',
                                        _before: {
                                            content: '"Película: "',
                                            fontWeight: 'semibold'
                                        }
                                    }
                                }}
                            >
                                {log.movieTitle || '-'}
                            </Td>
                            <Td
                                sx={{
                                    '@media screen and (max-width: 768px)': {
                                        display: 'block',
                                        paddingBottom: '0.25rem',
                                        _before: {
                                            content: '"IP: "',
                                            fontWeight: 'semibold'
                                        }
                                    }
                                }}
                            >
                                {log.ip}
                            </Td>
                            <Td
                                sx={{
                                    '@media screen and (max-width: 768px)': {
                                        display: 'block',
                                        paddingBottom: '0.5rem',
                                        _before: {
                                            content: '"Fecha: "',
                                            fontWeight: 'semibold'
                                        }
                                    }
                                }}
                            >
                                {new Date(log.timestamp).toLocaleString()}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    )
}

export default AdminActivityLogs
