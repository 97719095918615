import axios from 'axios'
import { API_URL } from './urls'

// Crear instancia de Axios con configuración base
const api = axios.create({
    baseURL: API_URL
})

// Interceptor para añadir el token a cada solicitud
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('authToken')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

export default api
