import api from './axiosConfig'
import { API_URL } from './urls'
import { fetchMovieFromDB } from './movieService'
import { transformKeysToMatchAPI } from '../hooks/transformKeys'

/**
 * Fetches movie data from the OMDb API or database.
 * @param {string} year The year of the movie.
 * @param {string} title The title of the movie.
 * @returns {Promise<MovieDetails>} The movie data.
 */
export const fetchOmdb = async (year: string, title: string) => {
    try {
        const handleTitle = `${title} (${year})`
        const dbResponse = await fetchMovieFromDB(handleTitle, 'admin')

        if (dbResponse) {
            const formattedDbResponse = transformKeysToMatchAPI(dbResponse)
            return formattedDbResponse
        }

        const response = await api.get(`${API_URL}/api/movies/movie`, {
            params: { year, title }
        })

        return response.data
    } catch (error) {
        console.error(`Error fetching poster for ${title}:`, error)
        return null
    }
}

/**
 * Translates text to Spanish.
 * @param {string} text The text to translate.
 * @returns {Promise<string>} The translated text.
 */
export const translateToSpanish = async (text: string) => {
    try {
        const response = await api.post(`${API_URL}/api/external/translate`, {
            text
        })

        return response.data.translatedText
    } catch (error) {
        console.error(`Error translating text:`, error)
    }
}

/**
 * Fetches the YouTube trailer URL from the backend.
 * @param {string} year The year of the movie.
 * @param {string} title The title of the movie.
 * @returns {Promise<string | null>} The trailer URL.
 */
export const fetchMovieTrailer = async (year: string, title: string) => {
    try {
        const response = await api.get(`${API_URL}/api/movies/trailer`, {
            params: { year, title }
        })

        return response.data.trailerUrl || null
    } catch (error) {
        console.error(`Error fetching trailer for ${title}:`, error)
        return null
    }
}
