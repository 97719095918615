import {
    Box,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    IconButton,
    useTheme,
    HStack,
    Text
} from '@chakra-ui/react'
import {
    FiTrash2,
    FiChevronLeft,
    FiChevronRight,
    FiChevronsLeft,
    FiChevronsRight
} from 'react-icons/fi'
import { useAdminMovies } from '../../hooks/useAdminMovies'
import SpinnerCentered from '../Common/SpinnerCentered'
import { cleanTitle } from '../../utils/cleanMovieTitle'
import AdminModalDelete from './AdminModalDelete'

const AdminMovies = () => {
    const {
        movies,
        loading,
        currentPage,
        totalPages,
        setCurrentPage,
        isDeleteOpen,
        setIsDeleteOpen,
        selectedMovie,
        openDelete,
        confirmDelete
    } = useAdminMovies()

    const theme = useTheme()

    return (
        <Box overflowX="auto">
            {loading ? (
                <SpinnerCentered />
            ) : (
                <>
                    <Table
                        variant="simple"
                        bg={theme.colors.brand.card}
                        borderRadius="lg"
                        boxShadow="md"
                        width="100%"
                    >
                        <Thead>
                            <Tr>
                                <Th color={theme.colors.brand.accent}>Título</Th>
                                <Th color={theme.colors.brand.accent}>Año</Th>
                                <Th
                                    color={theme.colors.brand.accent}
                                    display={{ base: 'none', md: 'table-cell' }}
                                >
                                    Archivo
                                </Th>
                                <Th color={theme.colors.brand.accent}>Acción</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {movies.map((movie, i) => (
                                <Tr
                                    key={movie.title}
                                    _hover={{ bg: theme.colors.brand.header, transition: '0.2s' }}
                                >
                                    <Td>{cleanTitle(movie.title)}</Td>
                                    <Td>{movie.year}</Td>
                                    <Td display={{ base: 'none', md: 'table-cell' }}>
                                        {movie.files[0]?.name || 'Sin archivo'}
                                    </Td>
                                    <Td>
                                        <IconButton
                                            aria-label="Eliminar"
                                            icon={<FiTrash2 />}
                                            colorScheme="red"
                                            size="sm"
                                            onClick={() => openDelete(movie)}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                    <HStack mt={4} justify="center" spacing={2}>
                        <IconButton
                            aria-label="Primera página"
                            icon={<FiChevronsLeft />}
                            onClick={() => setCurrentPage(1)}
                            isDisabled={currentPage <= 2}
                            size="sm"
                        />
                        <IconButton
                            aria-label="Página anterior"
                            icon={<FiChevronLeft />}
                            onClick={() => setCurrentPage(p => Math.max(p - 1, 1))}
                            isDisabled={currentPage === 1}
                            size="sm"
                        />
                        <Text color={theme.colors.brand.text}>
                            Página {currentPage} de {totalPages}
                        </Text>
                        <IconButton
                            aria-label="Página siguiente"
                            icon={<FiChevronRight />}
                            onClick={() => setCurrentPage(p => Math.min(p + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                            size="sm"
                        />
                        <IconButton
                            aria-label="Última página"
                            icon={<FiChevronsRight />}
                            onClick={() => setCurrentPage(totalPages)}
                            isDisabled={currentPage === totalPages}
                            size="sm"
                        />
                    </HStack>

                    <AdminModalDelete
                        isOpen={isDeleteOpen}
                        onClose={() => setIsDeleteOpen(false)}
                        title="Eliminar Película"
                        message={`Estás seguro que querés eliminar la película "${selectedMovie?.title}"?`}
                        onConfirm={confirmDelete}
                    />
                </>
            )}
        </Box>
    )
}

export default AdminMovies
