import React from 'react'
import { Badge } from '@chakra-ui/react'
import { useBadgeStyles } from '../../constants/styles/badgeStyles'
import { FaStar } from 'react-icons/fa'

const NewBadge: React.FC = () => {
    const badgeProps = useBadgeStyles()

    return (
        <Badge {...badgeProps}>
            <FaStar size={14} />
        </Badge>
    )
}

export default NewBadge
