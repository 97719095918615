import { FaThumbsDown, FaMeh, FaThumbsUp, FaStar, FaPoo } from 'react-icons/fa'
import { Rating } from '../../Common/MovieListTooltip'

export const getRatingIcon = (rating: number | null) => {
    if (rating === null) return null
    if (rating < 1.5) return <FaPoo color="#8B4513" size={21} />
    if (rating < 2.5) return <FaThumbsDown color="red" size={21} />
    if (rating < 3.5) return <FaMeh color="orange" size={21} />
    if (rating < 4.5) return <FaThumbsUp color="green" size={21} />
    return <FaStar color="yellow" size={21} />
}

export const getRatingText = (rating: number | null) => {
    if (rating === null) return 'Sin calificación'
    if (rating < 1.5) return 'Muy mala'
    if (rating < 2.5) return 'Mala'
    if (rating < 3.5) return 'Regular'
    if (rating < 4.5) return 'Buena'
    return 'Excelente'
}

export const calculateAverageRating = (ratings: Rating[]): number | null => {
    if (!ratings || ratings.length === 0) return null

    const numericRatings = ratings
        .map(({ Source, Value }) => {
            if (Source.includes('Internet Movie Database')) {
                const imdbRating = parseFloat(Value.split('/')[0])
                return imdbRating / 2
            }
            if (Source.includes('Rotten Tomatoes')) {
                const rtRating = parseFloat(Value.replace('%', ''))
                return rtRating / 20
            }
            if (Source.includes('Metacritic')) {
                const mcRating = parseFloat(Value.split('/')[0])
                return mcRating / 20
            }
            return NaN
        })
        .filter(value => !isNaN(value))

    if (numericRatings.length === 0) return null

    const average = numericRatings.reduce((acc, val) => acc + val, 0) / numericRatings.length

    return parseFloat(average.toFixed(1))
}
