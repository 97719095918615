import api from './axiosConfig'
import { API_URL } from './urls'
import { cleanTitle } from '../utils/cleanMovieTitle'
import { fetchOmdb } from './externalService'
import { VideoProgressResponse } from '../interfaces/global'
import useMovieStore from '../stores/movieStore'
import { Movie, MoviesResponse } from '../interfaces/movie'

/**
 * Fetches movies from the local API
 * @param {string} username - The username
 * @param {number} page - The page number
 * @param {number} limit - The limit number
 * @returns {Promise<MoviesResponse} The list of movies
 */
export const fetchMovies = async (
    username: string | null,
    page: number | null = 1,
    limit: number | null = 24
): Promise<MoviesResponse> => {
    try {
        const response = await api.get(`${API_URL}/api/movies`, {
            params: { username, page, limit }
        })
        return response.data
    } catch (error) {
        console.error('❌ Error fetching movies from API:', error)
        throw error
    }
}

/**
 * Fetches movie details from the database by title
 * @param {string} title - The movie title
 * @returns {Promise<any>} The movie data
 */
export const fetchMovieFromDB = async (title: string, username: string): Promise<any> => {
    try {
        const response = await api.get(
            `${API_URL}/api/movies/db-movies/title/${encodeURIComponent(title)}`,
            { params: { username } }
        )
        return response.data
    } catch (error) {
        console.error(`❌ Error fetching movie from DB: ${title}`, error)
        return null
    }
}

/**
 * Fetches movie details from the database by ID
 * @param {string} id - The movie ID
 * @returns {Promise<any>} The movie data
 */
export const fetchDBMovies = async (): Promise<any> => {
    try {
        const response = await api.get(`${API_URL}/api/movies/db-movies`)
        return response.data
    } catch (error) {
        console.error('❌ Error fetching movies from DB:', error)
        return null
    }
}

/**
 * Fetches subtitle text for a movie
 * @param {string} year - The movie release year
 * @param {string} title - The movie title
 * @param {string} subtitle - The subtitle filename
 * @returns {Promise<string | null>} The subtitle text or null
 */
export const fetchSubtitleText = async (
    year: string,
    title: string,
    subtitle: string,
    username: string
): Promise<string | null> => {
    try {
        const response = await api.get(
            `${API_URL}/api/movies/subtitles/${year}/${encodeURIComponent(
                title
            )}/${encodeURIComponent(subtitle)}`,
            { params: { username } }
        )
        return response.data.subtitleText || null
    } catch {
        console.warn(`⚠️ No subtitle found for: ${title} (${year})`)
        return null
    }
}

/**
 * Fetches the latest timestamp from the local API
 * @returns {Promise<number>} The latest timestamp
 */
export const fetchLatestTimestamp = async (username: string): Promise<number> => {
    try {
        const response = await api.get(`${API_URL}/api/movies/latest-timestamp`, {
            params: { username }
        })
        return response.data.latestTimestamp
    } catch (error) {
        console.error('Error fetching latest timestamp:', error)
        return 0
    }
}

/**
 * Fetches movie data from the local API or from OMDB
 * @param {string} title - The movie title
 * @param {string} year - The movie release year
 * @param {'details' | 'poster'} type - The type of data to fetch
 * @returns {Promise<{ data: any; key: string } | null>} The movie data or null
 */
export const getStoredOrFetchedMovieData = async (
    title: string,
    year: string,
    type: 'details' | 'poster'
): Promise<{ data: any; key: string } | null> => {
    try {
        const cleanedTitle = cleanTitle(title)
        const key = `${cleanedTitle}_${year}_${type}`

        const moviePosters = useMovieStore.getState().moviePosters
        const setMoviePosters = useMovieStore.getState().setMoviePosters
        const movieDetails = useMovieStore.getState().movieDetails
        const setMovieDetails = useMovieStore.getState().setMovieDetails

        const cache = type === 'poster' ? moviePosters[key] : movieDetails[key]
        if (cache) {
            return { data: cache, key }
        }

        // Si no está en Zustand, fetchear desde OMDB
        const omdbData = await fetchOmdb(year, cleanedTitle)
        if (omdbData) {
            const dataToStore = type === 'poster' ? omdbData.Poster : omdbData

            // Guardar en el store de Zustand
            if (type === 'poster') {
                setMoviePosters(prev => ({ ...prev, [key]: String(dataToStore) }))
            } else {
                setMovieDetails(prev => ({ ...prev, [key]: dataToStore }))
            }

            return { data: dataToStore, key }
        }

        return null
    } catch (error) {
        console.error(`❌ Error fetching movie data for ${title} (${year}):`, error)
        return null
    }
}

/**
 * Fetches movie progress from the local API
 * @param {string} username - The username
 * @param {string} title - The title of the movie
 * @param {string} year - The year of the movie
 * @returns {Promise<VideoProgressResponse>} The movie progress
 */
export const fetchMovieProgress = async (
    username: string | null,
    title: string | undefined,
    year: string
): Promise<VideoProgressResponse> => {
    try {
        const response = await api.get(`${API_URL}/api/movie-progress`, {
            params: { username, title, year }
        })
        return { progress: response.data.progress, isMuted: response.data.isMuted }
    } catch (error) {
        console.error(`Error fetching movie progress for ${title} (${year}):`, error)
        return { progress: 0, isMuted: false }
    }
}

/**
 * Saves movie progress to the local API
 * @param {string} username - The username
 * @param {string} title - The title of the movie
 * @param {string} year - The year of the movie
 * @param {number} progress - The progress of the movie
 * @param {boolean} isMuted - Whether the movie is muted
 */
export const saveMovieProgress = async (
    username: string | null,
    title: string | undefined,
    year: string,
    progress: number,
    isMuted: boolean
) => {
    try {
        await api.post(`${API_URL}/api/movie-progress/save`, {
            username,
            title,
            year,
            progress,
            isMuted
        })
    } catch (error) {
        console.error(`Error saving movie progress for ${title} (${year}):`, error)
    }
}

/**
 * Fetches processed subtitles from the backend
 * @param {string} subtitleText - The subtitle text to process
 * @param {string} title - The title of the movie
 * @returns {Promise<string | null>} The processed subtitle text in WebVTT format or null
 */
export const processSubtitleText = async (
    subtitleText: string,
    title: string
): Promise<string | null> => {
    try {
        const response = await api.post(`${API_URL}/api/movies/process-subtitles`, {
            subtitleText,
            title
        })
        return response.data.subtitleText || null
    } catch (error) {
        throw error
    }
}

/**
 * Fetches movies by search query
 * @param {string} query - The search query
 * @returns {Promise<any[]>} The list of matching movies
 */
export const fetchMoviesBySearch = async (query: string): Promise<Movie[]> => {
    try {
        const response = await api.get(`${API_URL}/api/movies/search`, {
            params: { query }
        })
        return response.data.movies
    } catch (error) {
        console.error(`❌ Error searching for movies: ${query}`, error)
        return []
    }
}
