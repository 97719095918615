import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    IconButton,
    HStack,
    Box,
    useTheme,
    Text
} from '@chakra-ui/react'
import { FaPalette } from 'react-icons/fa'
import { ThemeName, useThemeStore } from '../../stores/themeStore'

const ThemeSelector = () => {
    const { setTheme, themeName, allThemes } = useThemeStore()
    const theme = useTheme()

    const themes = [
        { name: 'Main', value: 'main', color: allThemes.main.ACCENT },
        { name: 'Yellow Night', value: 'yellow_night', color: allThemes.yellow_night.ACCENT },
        { name: 'Cyberpunk', value: 'cyberpunk', color: allThemes.cyberpunk.ACCENT },
        { name: 'Horror', value: 'horror', color: allThemes.horror.ACCENT },
        { name: 'Synthwave', value: 'synthwave', color: allThemes.synthwave.ACCENT },
        { name: 'Cyber Neon', value: 'cyber_neon', color: allThemes.cyber_neon.ACCENT },
        { name: 'Deep Blue', value: 'deep_blue', color: allThemes.deep_blue.ACCENT },
        { name: 'Neo Tokyo', value: 'neo_tokyo', color: allThemes.neo_tokyo.ACCENT },
        { name: 'Vintage Sepia', value: 'vintage_sepia', color: allThemes.vintage_sepia.ACCENT }
    ]

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                icon={<FaPalette />}
                variant="ghost"
                fontSize="1.5rem"
                aria-label="Seleccionar Tema"
                mr={4}
            />
            <MenuList
                bg={theme.colors.brand.background}
                color={theme.colors.brand.text}
                borderColor={theme.colors.brand.header}
                transition="all 0.3s ease-in-out"
            >
                {themes.map(({ name, value, color }) => (
                    <MenuItem
                        key={value}
                        onClick={() => setTheme(value as ThemeName)}
                        color={
                            themeName === value
                                ? theme.colors.brand.accent
                                : theme.colors.brand.text
                        }
                        fontWeight={themeName === value ? 'bold' : 'normal'}
                    >
                        <HStack spacing={3}>
                            <Box
                                w="16px"
                                h="16px"
                                borderRadius="50%"
                                bg={color}
                                border={
                                    themeName === value
                                        ? '2px solid white'
                                        : '2px solid transparent'
                                }
                            />
                            <Text>{name}</Text>{' '}
                        </HStack>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

export default ThemeSelector
