import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { ThemeType } from '../stores/themeStore'

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false
}

export const getChakraTheme = (theme: ThemeType) => {
    const generatedTheme = extendTheme({
        config,
        styles: {
            global: {
                body: {
                    bg: 'brand.background',
                    color: 'brand.text',
                    transition: 'background-color 0.2s ease-in-out',
                    fontWeight: 'normal'
                }
            }
        },
        components: {
            Select: {
                baseStyle: {
                    field: {
                        bg: 'gray.800',
                        color: 'whiteAlpha.900',
                        _placeholder: {
                            color: 'gray.500'
                        }
                    }
                }
            }
        },
        colors: {
            brand: {
                background: theme.MAIN_BG,
                card: theme.CARD_BG,
                header: theme.HEADER_BG,
                accent: theme.ACCENT,
                text: theme.TEXT
            }
        },
        fonts: {
            heading: `'Obvia', sans-serif`,
            body: `'Obvia', sans-serif`
        }
    })

    return generatedTheme
}
