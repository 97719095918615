import {
    Box,
    Input,
    Button,
    Heading,
    VStack,
    InputGroup,
    InputRightElement,
    IconButton
} from '@chakra-ui/react'
import useLogin from '../../hooks/useLogin'
import SpinnerCentered from '../Common/SpinnerCentered'
import { useLoginStyles } from '../../constants/styles/loginStyles'

const Login: React.FC = () => {
    const {
        handleSubmit,
        isLoading,
        password,
        setUsername,
        setPassword,
        showPassword,
        togglePasswordVisibility,
        username
    } = useLogin()

    // 📌 Obtener estilos desde el hook
    const { boxProps, headingProps, inputProps, buttonProps, iconButtonProps } = useLoginStyles()

    return (
        <Box {...boxProps}>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <Heading {...headingProps}>Mamovies</Heading>
                    <Input
                        placeholder="Usuario"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        {...inputProps}
                    />
                    <InputGroup>
                        <Input
                            placeholder="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            {...inputProps}
                        />
                        <InputRightElement>
                            <IconButton
                                {...iconButtonProps(showPassword)}
                                onClick={togglePasswordVisibility}
                            />
                        </InputRightElement>
                    </InputGroup>
                    <Button {...buttonProps} isLoading={isLoading}>
                        {isLoading ? <SpinnerCentered /> : 'Iniciar Sesión'}
                    </Button>
                </VStack>
            </form>
        </Box>
    )
}

export default Login
