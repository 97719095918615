import { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import useAuthStore from '../stores/authStore'
import { login } from '../api/authService'

const useLogin = () => {
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const toast = useToast()

    const handleLoginSuccess = useAuthStore(state => state.handleLoginSuccess)
    const isLoading = useAuthStore(state => state.isLoading)
    const setLoading = useAuthStore(state => state.setLoading)
    const username = useAuthStore(state => state.username)
    const setUsername = useAuthStore(state => state.setUsername)

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev)
    }

    const handleLogin = async (username: string, password: string) => {
        setLoading(true)
        try {
            const response = await login(username, password, toast)

            const { accessToken, usernameFromToken } = response

            if (usernameFromToken) {
                handleLoginSuccess(accessToken, usernameFromToken)
            }
        } catch (error: any) {
            console.error('Error on login:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        handleLogin(username, password)
    }

    return {
        handleSubmit,
        isLoading,
        password,
        setUsername,
        setPassword,
        showPassword,
        togglePasswordVisibility,
        username
    }
}

export default useLogin
