import { Box, Badge, Divider, Image, Text, Link, Icon, VStack, Flex } from '@chakra-ui/react'
import { FaPlayCircle } from 'react-icons/fa'
import MovieVideoPlayer from '../components/Movie/MovieVideoPlayer'
import useMoviePage from '../hooks/useMoviePage'
import SpinnerCentered from '../components/Common/SpinnerCentered'
import { textProps, highlightedTextProps } from '../constants/styles/moviePageStyles'
import { cleanTitle } from '../utils/cleanMovieTitle'

const MoviePage = () => {
    const { movie, currentSubtitleText, currentVideoUrl } = useMoviePage()

    if (!movie || !currentVideoUrl) return <SpinnerCentered />

    return (
        <Box maxW="1200px" mx="auto" mt={10} p={6} bg="gray.900" borderRadius="lg" boxShadow="2xl">
            {/* 🎬 Video Section */}
            <Box mb={6} borderRadius="lg" overflow="hidden" boxShadow="xl">
                {currentVideoUrl && (
                    <MovieVideoPlayer
                        title={movie.Title}
                        videoUrl={currentVideoUrl}
                        subtitleText={currentSubtitleText}
                    />
                )}
            </Box>

            {/* 📝 Information Section */}
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align="center"
                justify="space-between"
                gap={6}
            >
                {movie.poster && (
                    <Box flexShrink={0}>
                        <Image
                            src={movie.poster}
                            alt={`${movie.Title} poster`}
                            width={{ base: '180px', md: '250px' }}
                            height={{ base: '270px', md: '375px' }}
                            objectFit="cover"
                            borderRadius="lg"
                            boxShadow="xl"
                        />
                    </Box>
                )}

                <VStack
                    align={{ base: 'center', md: 'flex-start' }}
                    spacing={4}
                    textAlign={{ base: 'center', md: 'left' }}
                >
                    <Badge
                        fontSize="2xl"
                        color="white"
                        px={4}
                        py={2}
                        borderRadius="lg"
                        fontStyle="italic"
                    >
                        {cleanTitle(movie.title)}
                    </Badge>
                    <Divider borderColor="gray.600" w="full" />

                    <Text {...highlightedTextProps}>
                        Año:{' '}
                        <Text as="span" {...textProps}>
                            {movie.year}
                        </Text>
                    </Text>
                    <Text {...highlightedTextProps}>
                        Género:{' '}
                        <Text as="span" {...textProps}>
                            {movie.genre}
                        </Text>
                    </Text>
                    <Text {...highlightedTextProps}>
                        Director:{' '}
                        <Text as="span" {...textProps}>
                            {movie.director}
                        </Text>
                    </Text>
                    <Text {...highlightedTextProps}>
                        Reparto:{' '}
                        <Text as="span" {...textProps}>
                            {movie.cast}
                        </Text>
                    </Text>
                    <Text {...highlightedTextProps}>
                        Sinopsis:{' '}
                        <Text as="span" {...textProps}>
                            {movie.translatedPlot || movie.plot}
                        </Text>
                    </Text>

                    {/* 🎥 Trailer */}
                    {movie.trailerUrl && (
                        <Link
                            href={movie.trailerUrl}
                            isExternal
                            display="flex"
                            alignItems="center"
                            gap={3}
                            fontSize="xl"
                            fontWeight="bold"
                            color="red.400"
                            mt={3}
                            transition="all 0.2s ease-in-out"
                            _hover={{ color: 'red.300', transform: 'scale(1.1)' }}
                        >
                            <Icon as={FaPlayCircle} boxSize={6} /> Ver tráiler
                        </Link>
                    )}
                </VStack>
            </Flex>
        </Box>
    )
}

export default MoviePage
