import { isLocalHost } from '../utils/isLocalHost'

export const API_URL = isLocalHost() ? 'http://localhost:4000' : 'https://maimovies-be.onrender.com'

export const API_USER_URL = isLocalHost()
    ? 'http://localhost:4000/api/user'
    : 'https://maimovies-be.onrender.com/api/user'

export const STREAM_URL = (
    year: string,
    title: string,
    fileName: string,
    username: string,
    networkSpeed: string
) =>
    `${API_URL}/api/movies/stream/${year}/${encodeURIComponent(title)}/${encodeURIComponent(
        fileName
    )}/${username}?networkSpeed=${networkSpeed}`
