import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { Movie } from '../interfaces/movie'
import { localStorageAdapter } from '../utils/localStorageAdapter'
import { API_URL } from '../api/urls'
import axios from 'axios'

interface MovieState {
    currentVideoUrl: string | null
    currentSubtitleText: string | null
    currentMovieDetails: any
    displayedMovies: Movie[]
    isLoading: boolean
    isLoadingPlayer: boolean
    isModalReady: boolean
    lastUpdated: number
    movies: Movie[]
    moviePage: number
    moviePosters: { [key: string]: string }
    movieDetails: { [key: string]: any }
    selectedMovie: { title: string; year: string } | null

    fetchLatestTimestamp: (username: string) => Promise<number>
    setCurrentSubtitleText: (currentSubtitleText: string | null) => void
    setCurrentVideoUrl: (currentVideoUrl: string | null) => void
    setDisplayedMovies: (displayedMovies: Movie[]) => void
    setIsLoading: (isLoading: boolean) => void
    setIsModalReady: (isModalReady: boolean) => void
    setMovies: (movies: Movie[], timestamp?: number) => void
    setMoviePage: (moviePage: number) => void
    setMovieDetails: (
        updater: { [key: string]: any } | ((prev: { [key: string]: any }) => { [key: string]: any })
    ) => void
    setMoviePosters: (
        updater:
            | { [key: string]: string }
            | ((prev: { [key: string]: string }) => { [key: string]: string })
    ) => void
    setSelectedMovie: (selectedMovie: { title: string; year: string } | null) => void
}

const useMovieStore = create<MovieState>()(
    devtools(
        persist(
            (set, get) => ({
                currentVideoUrl: null,
                currentSubtitleText: null,
                currentMovieDetails: null,
                displayedMovies: [],
                isLoading: true,
                isLoadingPlayer: false,
                isModalReady: false,
                lastUpdated: 0,
                movies: [],
                moviePage: 1,
                moviePosters: {},
                movieDetails: {},
                selectedMovie: null,

                setCurrentSubtitleText: (currentSubtitleText: string | null) =>
                    set({ currentSubtitleText }),
                setDisplayedMovies: (displayedMovies: Movie[]) => set({ displayedMovies }),
                setCurrentVideoUrl: (currentVideoUrl: string | null) => set({ currentVideoUrl }),
                setIsLoading: (isLoading: boolean) => set({ isLoading }),
                setIsModalReady: (isModalReady: boolean) => set({ isModalReady }),
                setMovies: (movies: Movie[], timestamp?: number) => {
                    const storedMovies = get().movies
                    const { lastUpdated } = get()

                    if (!timestamp) return set({ movies })

                    if (timestamp > lastUpdated) {
                        set({ movies: { ...storedMovies, ...movies }, lastUpdated: timestamp })
                    }
                },
                setMovieDetails: (
                    updater:
                        | { [key: string]: any }
                        | ((prev: { [key: string]: any }) => { [key: string]: any })
                ) =>
                    set(state => ({
                        movieDetails:
                            typeof updater === 'function' ? updater(state.movieDetails) : updater
                    })),
                setMoviePage: (moviePage: number) => set({ moviePage }),
                setMoviePosters: (
                    updater:
                        | { [key: string]: string }
                        | ((prev: { [key: string]: string }) => { [key: string]: string })
                ) =>
                    set(state => ({
                        moviePosters:
                            typeof updater === 'function' ? updater(state.moviePosters) : updater
                    })),
                setSelectedMovie: (selectedMovie: { title: string; year: string } | null) =>
                    set({ selectedMovie }),
                fetchLatestTimestamp: async (username: string) => {
                    try {
                        const response = await axios.get(`${API_URL}/api/movies/latest-timestamp`, {
                            params: { username }
                        })
                        return response.data.timestamp
                    } catch (error) {
                        console.error('❌ Error fetching latest timestamp:', error)
                        return 0
                    }
                }
            }),
            {
                name: 'movie-storage',
                storage: localStorageAdapter
            }
        )
    )
)

export default useMovieStore
