import { useState, useEffect } from 'react'

type NetworkSpeed = 'slow' | 'medium' | 'fast'

const getEffectiveNetworkSpeed = (): NetworkSpeed => {
    if ('connection' in navigator) {
        const connection =
            (navigator as any).connection ||
            (navigator as any).mozConnection ||
            (navigator as any).webkitConnection

        if (connection.effectiveType) {
            const type = connection.effectiveType
            if (type.includes('2g')) return 'slow'
            if (type.includes('3g')) return 'medium'
            if (type.includes('4g') || type.includes('5g') || type.includes('wifi')) return 'fast'
        }
    }
    return 'medium'
}

const useNetworkSpeed = (): NetworkSpeed => {
    const [networkSpeed, setNetworkSpeed] = useState<NetworkSpeed>(getEffectiveNetworkSpeed())

    useEffect(() => {
        const updateSpeed = () => setNetworkSpeed(getEffectiveNetworkSpeed())

        if ('connection' in navigator) {
            const connection =
                (navigator as any).connection ||
                (navigator as any).mozConnection ||
                (navigator as any).webkitConnection
            connection.addEventListener('change', updateSpeed)
            return () => connection.removeEventListener('change', updateSpeed)
        }
    }, [])

    return networkSpeed
}

export default useNetworkSpeed
