import {
    useTheme,
    BoxProps,
    ButtonProps,
    InputProps,
    TextProps,
    StackProps,
    CenterProps
} from '@chakra-ui/react'

interface VideoUploadStyles {
    centerProps: CenterProps
    boxProps: BoxProps
    vStackProps: StackProps
    inputProps: InputProps
    buttonProps: ButtonProps
    textProps: TextProps
}

export const useVideoUploadStyles = (): VideoUploadStyles => {
    const theme = useTheme()

    return {
        centerProps: {
            minH: '80vh'
        },
        boxProps: {
            p: 6,
            boxShadow: 'lg',
            borderWidth: '1px',
            borderRadius: 'lg',
            bg: theme.colors.brand.card,
            borderColor: theme.colors.brand.accent
        },
        vStackProps: {
            spacing: 6,
            align: 'center'
        },
        inputProps: {
            type: 'file',
            accept: 'video/*',
            display: 'block',
            mx: 'auto',
            maxW: '100%',
            borderColor: theme.colors.brand.accent,
            height: '33px',
            color: theme.colors.brand.text
        },
        buttonProps: {
            bg: theme.colors.brand.accent,
            color: theme.colors.brand.text,
            _hover: { opacity: 0.8 },
            w: 'full'
        },
        textProps: {
            color: theme.colors.brand.text
        }
    }
}
