import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, TabProps, useTheme } from '@chakra-ui/react'
import AdminUsers from './AdminUsers'
import AdminActivityLogs from './AdminActivityLogs'
import AdminMovies from './AdminMovies'

const AdminDashboard = () => {
    const theme = useTheme()

    const tabStyles: TabProps = {
        _selected: {
            color: theme.colors.brand.accent,
            borderBottom: `2px solid ${theme.colors.brand.accent}`,
            fontWeight: 'bold'
        },
        _hover: {
            color: theme.colors.brand.accent,
            bg: theme.colors.brand.card
        },
        transition: 'all 0.2s ease-in-out'
    }

    return (
        <Box
            p={6}
            bg={theme.colors.brand.background}
            minHeight="100vh"
            color={theme.colors.brand.text}
        >
            <Tabs variant="unstyled">
                <TabList mb={4}>
                    <Tab {...tabStyles}>Usuarios</Tab>
                    <Tab {...tabStyles}>Actividad</Tab>
                    <Tab {...tabStyles}>Películas</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <AdminUsers />
                    </TabPanel>
                    <TabPanel>
                        <AdminActivityLogs />
                    </TabPanel>
                    <TabPanel>
                        <AdminMovies />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default AdminDashboard
