import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface PaginationState {
    currentPage: number
    totalPages: number
    setCurrentPage: (updater: number | ((prev: number) => number)) => void
    setTotalPages: (total: number) => void
}

const usePaginationStore = create<PaginationState>()(
    devtools(set => ({
        currentPage: 1,
        totalPages: 1,

        setCurrentPage: updater =>
            set(state => ({
                currentPage: typeof updater === 'function' ? updater(state.currentPage) : updater
            })),

        setTotalPages: (total: number) => set({ totalPages: total })
    }))
)

export default usePaginationStore
