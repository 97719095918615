import { useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import { isIpad } from '../../../utils/isIpad'

interface VideoPlayerProps {
    videoRef: React.RefObject<ReactPlayer>
    videoUrl: string
    isPlaying: boolean
    isMuted: boolean
    volume: number
    togglePlayPause: (playing: boolean) => void
    handleProgress: (state: { played: number; playedSeconds: number }) => void
    handleDuration: (duration: number) => void
    subtitleUrl: string | null
    setIsLoading: (loading: boolean) => void
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    videoRef,
    videoUrl,
    isPlaying,
    isMuted,
    volume,
    togglePlayPause,
    handleProgress,
    handleDuration,
    subtitleUrl,
    setIsLoading
}) => {
    const htmlVideoRef = useRef<HTMLVideoElement | null>(null)

    // 🔹 Buscar el <video> de ReactPlayer en el DOM y agregar los subtítulos manualmente
    useEffect(() => {
        if (isIpad() && subtitleUrl) {
            const checkVideoElement = setInterval(() => {
                const videoElement = document.querySelector('video') as HTMLVideoElement
                if (videoElement) {
                    htmlVideoRef.current = videoElement

                    // 🔹 Crear y agregar el track de subtítulos
                    const track = document.createElement('track')
                    track.kind = 'subtitles'
                    track.label = 'Spanish'
                    track.srclang = 'es'
                    track.src = subtitleUrl
                    track.default = true

                    // 🔹 Limpiar tracks previos y agregar el nuevo
                    videoElement.querySelectorAll('track').forEach(t => t.remove())
                    videoElement.appendChild(track)

                    clearInterval(checkVideoElement) // ✅ Detener la búsqueda
                }
            }, 500) // Revisar cada 500ms hasta encontrar el <video>

            return () => clearInterval(checkVideoElement)
        }
    }, [subtitleUrl])

    return (
        <ReactPlayer
            ref={videoRef}
            url={videoUrl}
            playing={isPlaying}
            muted={isMuted}
            volume={volume}
            controls={isIpad()}
            width="100%"
            height="100%"
            onPlay={() => togglePlayPause(true)}
            onPause={() => togglePlayPause(false)}
            onReady={() => setIsLoading(false)}
            onProgress={handleProgress}
            onDuration={handleDuration}
            playsinline
            config={{
                file: {
                    attributes: {
                        crossOrigin: 'anonymous',
                        playsInline: true,
                        muted: isMuted,
                        preload: 'auto'
                    },
                    tracks: subtitleUrl
                        ? [
                              {
                                  kind: 'subtitles',
                                  src: subtitleUrl,
                                  srcLang: 'es',
                                  label: 'Spanish',
                                  default: true
                              }
                          ]
                        : []
                }
            }}
            style={{
                borderRadius: '8px'
            }}
        />
    )
}

export default VideoPlayer
