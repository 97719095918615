import { Box } from '@chakra-ui/react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useEffect, useState, useTransition } from 'react'
import ProtectedRoute from './ProtectedRoute'
import VideoUpload from './VideoUpload'
import SpinnerCentered from './SpinnerCentered'
import useMovieList from '../../hooks/useMovieList'
import Register from '../User/Register'
import { isAdminUser } from '../../utils/isAdminUser'
import MoviePage from '../../pages/MoviePage'
import MovieList from '../Movie/MovieList'
import AdminDashboard from '../Admin/AdminDashboard'

interface AppContentProps {
    isAuthenticated: boolean
    username: string
}

const AppContent = ({ isAuthenticated, username }: AppContentProps) => {
    const isAdmin = isAdminUser(username)

    const location = useLocation()
    const [isPending, startTransition] = useTransition()
    const [isLoading, setIsLoading] = useState(false)

    const movieListData = useMovieList()

    useEffect(() => {
        startTransition(() => {
            setIsLoading(true)
            setTimeout(() => setIsLoading(false), 300)
        })
    }, [location])

    if (isPending || isLoading) {
        return <SpinnerCentered />
    }

    return (
        <Box>
            <Routes>
                <Route path="/" element={<Navigate to="/movies" replace />} />
                <Route path="/movies" element={<MovieList movieListData={movieListData} />} />
                <Route
                    path="/upload"
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
                            <VideoUpload />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
                            <Register />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
                            <AdminDashboard />
                        </ProtectedRoute>
                    }
                />
                <Route path="/movie/:movie_id" element={<MoviePage />} />
                <Route path="*" element={<Navigate to="/movies" replace />} />
            </Routes>
        </Box>
    )
}

export default AppContent
