import { Box, GridItem, HStack, Text, VStack } from '@chakra-ui/react'
import NewBadge from '../Common/NewBadge'
import SpinnerCentered from '../Common/SpinnerCentered'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { cleanTitle } from '../../utils/cleanMovieTitle'
import MovieListTooltip from '../Common/MovieListTooltip'
import { useState } from 'react'
import { useMovieListItemStyles } from '../../constants/styles/movieListItemStyles'
import { motion } from 'framer-motion' // 🔹 Importamos Framer Motion

interface MovieListItemProps {
    movie: any
    needsConversion: boolean
    isNew: boolean
    handleOpenPlayer: (year: number, title: string, file: string, subtitle: string) => void
    isLoadingPlayer: boolean
    selectedMovie?: any
}

const MovieListItem = ({
    movie,
    needsConversion,
    isNew,
    handleOpenPlayer,
    isLoadingPlayer,
    selectedMovie
}: MovieListItemProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    // 📌 Obtener estilos desde el hook
    const { gridItemProps, imageWrapperProps, textProps } = useMovieListItemStyles()

    return (
        <MovieListTooltip
            title={movie.title}
            description={movie.TranslatedPlot || movie.Plot}
            ratings={movie.Ratings}
        >
            {/* 🔹 Envolver en Motion.div para animaciones */}
            <motion.div
                whileHover={{
                    scale: 1.05, // Hace un ligero zoom al hacer hover
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.4)',
                    transition: { duration: 0.3, ease: 'easeInOut' }
                }}
                whileTap={{ scale: 0.97 }} // Efecto de "presionado"
            >
                <GridItem
                    {...gridItemProps}
                    onClick={() =>
                        !needsConversion &&
                        handleOpenPlayer(
                            movie.year,
                            movie.title,
                            movie.files[0],
                            movie.subtitles?.[0]
                        )
                    }
                >
                    <VStack align="center" position="relative">
                        {/* Spinner de carga si se está cargando el reproductor */}
                        {isLoadingPlayer &&
                            selectedMovie?.title === movie.title &&
                            selectedMovie?.year === movie.year && <SpinnerCentered />}

                        {/* Contenedor de la imagen con Framer Motion */}
                        <motion.div
                            whileHover={{
                                scale: 1.02, // Un poco de zoom en la imagen
                                filter: 'brightness(1.3)', // Hace que la imagen brille un poco
                                transition: { duration: 0.3 }
                            }}
                            style={{ borderRadius: '12px', overflow: 'hidden' }}
                        >
                            <Box {...imageWrapperProps}>
                                {/* Badge "NEW" flotante en la imagen */}
                                {/* {isNew && <NewBadge />} */}

                                <LazyLoadImage
                                    src={
                                        movie.poster ||
                                        movie.Poster ||
                                        'https://via.placeholder.com/220x330?text=No+Poster'
                                    }
                                    alt={`${movie.title} poster`}
                                    effect="blur"
                                    onLoad={() => setIsImageLoaded(true)}
                                    style={{
                                        opacity: isImageLoaded ? 1 : 0,
                                        width: '100%',
                                        height: '100%',
                                        maxHeight: '370px',
                                        aspectRatio: '2 / 3',
                                        objectFit: 'cover',
                                        borderRadius: '12px',
                                        transition: '300ms',
                                        filter: 'brightness(1.1)'
                                    }}
                                />
                            </Box>
                        </motion.div>

                        {/* 📌 Título bien alineado debajo de la imagen */}
                        {isImageLoaded && (
                            <HStack spacing={2} alignItems="center">
                                <Text {...textProps}>{cleanTitle(movie.title)}</Text>
                                {isNew && <NewBadge />}
                            </HStack>
                        )}
                    </VStack>
                </GridItem>
            </motion.div>
        </MovieListTooltip>
    )
}

export default MovieListItem
