import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    useTheme
} from '@chakra-ui/react'

interface AdminModalDeleteProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    title: string
    message: string
}

const AdminModalDelete = ({
    isOpen,
    onClose,
    onConfirm,
    title,
    message
}: AdminModalDeleteProps) => {
    const theme = useTheme()

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bg={theme.colors.brand.card} color={theme.colors.brand.text}>
                <ModalHeader color={theme.colors.brand.accent}>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{message}</Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button colorScheme="red" onClick={onConfirm}>
                        Eliminar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default AdminModalDelete
