import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { parseMovieTitle } from '../utils/movieUtils'
import { fetchMovies } from '../api/movieService'
import { STREAM_URL } from '../api/urls'
import useMovieStore from '../stores/movieStore'
import useAuthStore from '../stores/authStore'
import useNetworkSpeed from '../hooks/useNetworkSpeed'

const useMoviePage = () => {
    const currentVideoUrl = useMovieStore(state => state.currentVideoUrl)
    const setCurrentVideoUrl = useMovieStore(state => state.setCurrentVideoUrl)
    const username = useAuthStore(state => state.username)

    const [movie, setMovie] = useState<any | null>(null)
    const [currentSubtitleText, setCurrentSubtitleText] = useState<string | null>(null)
    const { movie_id } = useParams<{ movie_id: string }>()

    const networkSpeed = useNetworkSpeed()

    useEffect(() => {
        const fetchAndSetMovie = async () => {
            try {
                const { movies } = await fetchMovies(username, null, null)
                let foundMovie = movies.find((m: any) => parseMovieTitle(m.title) === movie_id)

                if (!foundMovie) {
                    console.error('❌ Película no encontrada:', movie_id)
                    return
                }

                const file = foundMovie?.files?.[0]
                if (foundMovie && file) {
                    const videoUrl = STREAM_URL(
                        foundMovie.year,
                        foundMovie.title,
                        file.name,
                        username,
                        networkSpeed
                    )
                    setCurrentVideoUrl(videoUrl)
                    setCurrentSubtitleText(foundMovie?.subtitleText ?? null)
                }

                if (foundMovie) {
                    setMovie(foundMovie)
                }
            } catch (error) {
                console.error('❌ Error fetching movies:', error)
            }
        }

        if (movie_id) {
            fetchAndSetMovie()
        }
    }, [movie_id])

    return { movie, currentSubtitleText, currentVideoUrl }
}

export default useMoviePage
