import { useBreakpointValue, useTheme, BoxProps, TextProps, GridItemProps } from '@chakra-ui/react'

export const useMovieListItemStyles = () => {
    const theme = useTheme()

    // 📌 Definir el número de columnas dinámicamente según el tamaño de la pantalla
    const columns = useBreakpointValue({ base: 2, sm: 3, md: 4, lg: 5, xl: 6 })

    // 📌 Estilos del GridItem (contenedor principal de la película)
    const gridItemProps: GridItemProps = {
        position: 'relative',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out'
    }

    // 📌 Estilos de la imagen (se asegura que todas sean uniformes)
    const imageProps: BoxProps = {
        position: 'relative',
        width: '100%',
        aspectRatio: '2 / 3', // 🔹 Mantiene la proporción siempre correcta
        borderRadius: '12px',
        objectFit: 'cover',
        transition: '300ms',
        filter: 'brightness(1.1)',
        _hover: {
            filter: 'brightness(1.2)'
        }
    }

    // 📌 Estilos del contenedor de la imagen (reserva el espacio correctamente)
    const imageWrapperProps: BoxProps = {
        position: 'relative',
        width: '100%',
        minHeight: '300px', // 🔹 Se asegura que el espacio de la imagen se mantenga
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    // 📌 Estilos del título (se posiciona correctamente)
    const textProps: TextProps = {
        fontSize: '20px',
        fontWeight: 'bold',
        color: theme.colors.brand.text,
        marginTop: '8px',
        textAlign: 'center',
        minHeight: '40px'
    }

    return { gridItemProps, imageProps, imageWrapperProps, textProps, columns }
}
