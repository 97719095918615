import { HStack, IconButton } from '@chakra-ui/react'
import { FaClosedCaptioning, FaExpand } from 'react-icons/fa'
import { useAditionalControlsStyles } from '../../../constants/styles/controlsStyles'

interface AditionalControlsProps {
    areSubtitlesVisible: boolean
    handleFullScreen: () => void
    subtitleUrl: string | null
    toggleSubtitles: () => void
    goToPreviousEpisode?: () => void
    goToNextEpisode?: () => void
    hasPreviousEpisode?: boolean
    hasNextEpisode?: boolean
    videoType: 'movie' | 'series'
}

const AditionalControls: React.FC<AditionalControlsProps> = ({
    areSubtitlesVisible,
    handleFullScreen,
    subtitleUrl,
    toggleSubtitles
}) => {
    const { hStackProps, iconButtonProps } = useAditionalControlsStyles()

    return (
        <HStack {...hStackProps}>
            {/* 🔹 Subtitles */}
            {subtitleUrl && (
                <IconButton
                    aria-label="Toggle Subtitles"
                    icon={<FaClosedCaptioning />}
                    onClick={toggleSubtitles}
                    {...iconButtonProps(areSubtitlesVisible)}
                />
            )}

            {/* 🔹 Fullscreen */}
            <IconButton
                aria-label="Fullscreen"
                icon={<FaExpand />}
                onClick={() => {
                    handleFullScreen()
                    if (
                        typeof window !== 'undefined' &&
                        window.screen.orientation &&
                        (window.screen.orientation as any).lock
                    ) {
                        ;(window.screen.orientation as any).lock('landscape').catch(() => {})
                    }
                }}
                {...iconButtonProps(false)}
            />
        </HStack>
    )
}

export default AditionalControls
