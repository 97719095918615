import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useTheme,
    Text,
    Flex,
    Stack,
    HStack,
    IconButton
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { FaTrashAlt, FaEdit, FaUser, FaUserSlash } from 'react-icons/fa'
import { useAdminUsers } from '../../hooks/useAdminUsers'
import AdminModalDelete from './AdminModalDelete'
import AdminModalEdit from './AdminModalEdit'

const AdminUsers = () => {
    const {
        visibleUsers,
        currentPage,
        totalPages,
        activeUsers,
        inactiveUsers,
        totalUsers,
        setCurrentPage,
        handleDisable,
        selectedUser,
        isEditOpen,
        isDeleteOpen,
        confirmDelete,
        confirmUpdate,
        setIsEditOpen,
        setIsDeleteOpen,
        openDelete,
        openEdit
    } = useAdminUsers()

    const theme = useTheme()

    return (
        <>
            <Box overflowX="auto">
                <Flex mb={4} gap={6} wrap="wrap">
                    <Stack spacing={0}>
                        <Text fontSize="sm" color={theme.colors.brand.text}>
                            Total
                        </Text>
                        <Text fontSize="lg" fontWeight="bold" color={theme.colors.brand.accent}>
                            {totalUsers}
                        </Text>
                    </Stack>
                    <Stack spacing={0}>
                        <Text fontSize="sm" color={theme.colors.brand.text}>
                            Activos
                        </Text>
                        <Text fontSize="lg" fontWeight="bold" color={theme.colors.brand.accent}>
                            {activeUsers}
                        </Text>
                    </Stack>
                    <Stack spacing={0}>
                        <Text fontSize="sm" color={theme.colors.brand.text}>
                            Desactivados
                        </Text>
                        <Text fontSize="lg" fontWeight="bold" color={theme.colors.brand.accent}>
                            {inactiveUsers}
                        </Text>
                    </Stack>
                </Flex>

                <Table
                    variant="simple"
                    bg={theme.colors.brand.card}
                    borderRadius="lg"
                    boxShadow="md"
                    width="100%"
                >
                    <Thead>
                        <Tr>
                            <Th color={theme.colors.brand.accent}>Username</Th>
                            <Th
                                color={theme.colors.brand.accent}
                                display={{ base: 'none', md: 'table-cell' }}
                            >
                                Email
                            </Th>
                            <Th
                                color={theme.colors.brand.accent}
                                display={{ base: 'none', md: 'table-cell' }}
                            >
                                Activo
                            </Th>
                            <Th color={theme.colors.brand.accent}>Acción</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {visibleUsers.map(user => {
                            const isDisabled = !user.isActive

                            return (
                                <Tr
                                    key={user._id}
                                    bg={isDisabled ? theme.colors.brand.card : 'transparent'}
                                    opacity={isDisabled ? 0.5 : 1}
                                    _hover={{
                                        bg: isDisabled
                                            ? theme.colors.brand.card
                                            : theme.colors.gray[800]
                                    }}
                                >
                                    <Td>{user.username}</Td>
                                    <Td display={{ base: 'none', md: 'table-cell' }}>
                                        {user.email}
                                    </Td>
                                    <Td display={{ base: 'none', md: 'table-cell' }}>
                                        {isDisabled ? 'No' : 'Sí'}
                                    </Td>
                                    <Td>
                                        <HStack spacing={2}>
                                            <IconButton
                                                size="sm"
                                                aria-label={
                                                    isDisabled
                                                        ? 'Habilitar usuario'
                                                        : 'Desactivar usuario'
                                                }
                                                icon={isDisabled ? <FaUser /> : <FaUserSlash />}
                                                colorScheme={isDisabled ? 'green' : 'red'}
                                                onClick={() => handleDisable(user._id)}
                                            />
                                            <IconButton
                                                size="sm"
                                                aria-label="Editar"
                                                icon={<FaEdit />}
                                                onClick={() => openEdit(user)}
                                                isDisabled={isDisabled}
                                                opacity={isDisabled ? 0.4 : 1}
                                                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                                            />
                                            <IconButton
                                                size="sm"
                                                aria-label="Eliminar"
                                                icon={<FaTrashAlt />}
                                                colorScheme="red"
                                                onClick={() => openDelete(user)}
                                                isDisabled={isDisabled}
                                                opacity={isDisabled ? 0.4 : 1}
                                                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                                            />
                                        </HStack>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>

                <HStack mt={4} justify="center">
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        aria-label="Anterior"
                        onClick={() => setCurrentPage(p => Math.max(p - 1, 1))}
                        isDisabled={currentPage === 1}
                        size="sm"
                    />
                    <Text color={theme.colors.brand.text}>
                        Página {currentPage} de {totalPages}
                    </Text>
                    <IconButton
                        icon={<ChevronRightIcon />}
                        aria-label="Siguiente"
                        onClick={() => setCurrentPage(p => Math.min(p + 1, totalPages))}
                        isDisabled={currentPage === totalPages}
                        size="sm"
                    />
                </HStack>
            </Box>

            {/* Modales */}
            <AdminModalEdit
                isOpen={isEditOpen}
                onClose={() => setIsEditOpen(false)}
                onSave={confirmUpdate}
                user={selectedUser}
            />

            {/* Modal de Confirmación para Borrar */}
            <AdminModalDelete
                isOpen={isDeleteOpen}
                onClose={() => setIsDeleteOpen(false)}
                onConfirm={confirmDelete}
                title="Eliminar Usuario"
                message={`¿Estás seguro que querés eliminar al usuario ${selectedUser?.username}?`}
            />
        </>
    )
}

export default AdminUsers
