import { Box, Grid, Heading, Text } from '@chakra-ui/react'
import MovieDetailModal from './MovieDetailModal'
import { genreTranslations } from '../../utils/genreTranslations'
import { strings } from '../../constants/strings'
import SpinnerCentered from '../Common/SpinnerCentered'
import MovieListItem from './MovieListItem'
import useFilteredMovies from '../../hooks/useFilteredMovies'
import Paginator from '../Common/Paginator'
import usePaginationStore from '../../stores/usePaginationStore'
import { useMovieListStyles } from '../../constants/styles/movieListStyles'
import { useMovieListItemStyles } from '../../constants/styles/movieListItemStyles'

const MovieList: React.FC<{ movieListData: any }> = ({ movieListData: mld }) => {
    const { filteredMovies, isNewMovie } = useFilteredMovies({
        movies: mld.movies,
        searchQuery: mld.searchQuery,
        movieDetails: mld.movieDetails,
        setDisplayedMovies: mld.setDisplayedMovies
    })

    const { currentPage, totalPages, setCurrentPage } = usePaginationStore()
    const { boxProps, headingProps, headingTextProps } = useMovieListStyles()
    const { columns } = useMovieListItemStyles()

    return (
        <Box {...boxProps}>
            <Paginator
                currentPage={currentPage}
                totalPages={totalPages}
                onNext={() => setCurrentPage((prev: number) => Math.min(totalPages, prev + 1))}
                onPrevious={() => setCurrentPage((prev: number) => Math.max(1, prev - 1))}
                onFirstPage={() => setCurrentPage(1)}
                onLastPage={() => setCurrentPage(totalPages)}
            />

            {mld.isLoading ? (
                <SpinnerCentered />
            ) : (
                <>
                    <Heading {...headingProps}>
                        {mld.selectedGenre && (
                            <>
                                {strings.moviesOf}{' '}
                                <Text {...headingTextProps}>
                                    {genreTranslations[mld.selectedGenre] || mld.selectedGenre}
                                </Text>
                            </>
                        )}
                    </Heading>

                    {filteredMovies.length > 0 && !mld.isLoading && (
                        <Grid
                            templateColumns={`repeat(${columns}, 1fr)`}
                            gap={{ base: 4, md: 8 }}
                            w="100%"
                        >
                            {mld.displayedMovies.map((movie: any, index: any) => {
                                const fileName = movie.files?.[0]?.name || ''
                                const needsConversion = !fileName.endsWith('.mp4')
                                const isNew = isNewMovie(movie.lastModified)

                                return (
                                    <MovieListItem
                                        movie={movie}
                                        needsConversion={needsConversion}
                                        isNew={isNew}
                                        handleOpenPlayer={mld.handleOpenPlayer}
                                        isLoadingPlayer={mld.isLoadingPlayer}
                                        selectedMovie={mld.selectedMovie}
                                        key={movie.title}
                                    />
                                )
                            })}
                        </Grid>
                    )}
                </>
            )}

            <MovieDetailModal
                isOpen={mld.isOpen && mld.isModalReady}
                onClose={() => {
                    mld.onClose()
                    mld.setIsModalReady(false)
                }}
                videoUrl={mld.currentVideoUrl}
                subtitleText={mld.currentSubtitleText}
                movieDetails={mld.currentMovieDetails}
                onLoaded={() => mld.setIsModalReady(true)}
            />
        </Box>
    )
}

export default MovieList
