import { MenuListProps, MenuItemProps, IconButtonProps, BoxProps, useTheme } from '@chakra-ui/react'

interface HamburguerMenuStyles {
    menuButtonProps: IconButtonProps
    menuListProps: MenuListProps
    menuItemProps: MenuItemProps
    saluteBoxProps: BoxProps
}

export const useHamburguerMenuStyles = (): HamburguerMenuStyles => {
    const theme = useTheme()

    return {
        menuButtonProps: {
            variant: 'ghost',
            colorScheme: 'white',
            _hover: { color: theme.colors.brand.accent },
            'aria-label': 'Menú',
            fontSize: '1.5rem',
            display: 'flex'
        },
        menuListProps: {
            bg: theme.colors.brand.background,
            color: 'white',
            borderColor: theme.colors.brand.header,
            py: 2,
            px: 2
        },
        menuItemProps: {
            _hover: { bg: theme.colors.brand.card },
            bg: theme.colors.brand.header
        },
        saluteBoxProps: {
            bg: theme.colors.brand.header,
            color: 'white',
            p: 3,
            borderRadius: 'md',
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 2,
            fontSize: 'lg'
        }
    }
}
