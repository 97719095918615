import {
    Center,
    Box,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    useTheme
} from '@chakra-ui/react'
import ProgressBar from './ProgressBar'
import useVideoUpload from '../../hooks/useVideoUpload'
import { useVideoUploadStyles } from '../../constants/styles/videoUploadStyles'

const VideoUpload: React.FC = () => {
    const {
        handleFileChange,
        handleUpload,
        uploadProgress,
        conversionProgress,
        isUploading,
        message,
        buttonLabel
    } = useVideoUpload()

    const { centerProps, boxProps, vStackProps, inputProps, buttonProps, textProps } =
        useVideoUploadStyles()

    const theme = useTheme()

    return (
        <Center {...centerProps}>
            <Box {...boxProps}>
                <VStack {...vStackProps}>
                    <FormControl>
                        <FormLabel
                            textAlign="center"
                            mb={4}
                            sx={{
                                color: textProps.color,
                                fontSize: textProps.fontSize,
                                fontWeight: textProps.fontWeight
                            }}
                        >
                            Sube un video para convertir a mp4
                        </FormLabel>
                        <Input {...inputProps} onChange={handleFileChange} />
                    </FormControl>
                    <Button
                        {...buttonProps}
                        onClick={handleUpload}
                        isDisabled={
                            isUploading ||
                            (conversionProgress !== null && conversionProgress !== 100)
                        }
                    >
                        {buttonLabel}
                    </Button>
                    {isUploading && (
                        <ProgressBar value={uploadProgress} color={theme.colors.brand.card} />
                    )}
                    {conversionProgress !== null && (
                        <ProgressBar value={conversionProgress} color={theme.colors.brand.accent} />
                    )}
                    {message && <Text {...textProps}>{message}</Text>}
                </VStack>
            </Box>
        </Center>
    )
}

export default VideoUpload
