export const transformKeysToMatchAPI = (dbData: Record<string, any>) => {
    const keyMappings: Record<string, string> = {
        plot: 'Plot',
        director: 'Director',
        genre: 'Genre',
        cast: 'Actors',
        poster: 'Poster',
        translatedPlot: 'TranslatedPlot',
        omdbRatings: 'Ratings',
        year: 'Year',
        title: 'Title',
        trailerUrl: 'TrailerUrl',
        _id: 'id'
    }

    return Object.entries(dbData).reduce((acc, [key, value]) => {
        if (key === 'subtitleText') return acc

        const newKey = keyMappings[key] || key
        acc[newKey] = value
        return acc
    }, {} as Record<string, any>)
}
