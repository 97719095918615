import { useState, ChangeEvent, useMemo } from 'react'
import axios from 'axios'
import { API_URL } from '../api/urls'

const useVideoUpload = () => {
    const [videoFile, setVideoFile] = useState<File | null>(null)
    const [message, setMessage] = useState<string>('')
    const [uploadProgress, setUploadProgress] = useState<number>(0)
    const [conversionProgress, setConversionProgress] = useState<number | null>(null)
    const [isUploading, setIsUploading] = useState<boolean>(false)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            document.title = 'Mamovies'
            setVideoFile(e.target.files[0])
        }
    }

    const handleUpload = async () => {
        if (!videoFile) {
            setMessage('Por favor selecciona un archivo de video para subir.')
            return
        }

        const formData = new FormData()
        formData.append('video', videoFile)

        try {
            setIsUploading(true)
            setMessage('')
            setUploadProgress(0)
            setConversionProgress(null)

            await axios.post(`${API_URL}/api/movies/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    )
                    setUploadProgress(percentCompleted)
                }
            })

            setMessage('¡Video subido y conversión iniciada!')

            const eventSource = new EventSource(`${API_URL}/api/movies/upload-progress`)

            eventSource.onmessage = event => {
                if (event.data === 'done') {
                    setMessage('Conversión completada!')
                    setVideoFile(null)
                    setConversionProgress(100)
                    document.title = '✔️ Conversión completada'

                    eventSource.close()
                } else if (event.data === 'error') {
                    setMessage('Error durante la conversión.')
                    eventSource.close()
                } else {
                    setConversionProgress(parseFloat(event.data))
                }
            }
        } catch (error) {
            setMessage('Error al subir el video: ' + (error as Error).message)
        } finally {
            setIsUploading(false)
        }
    }

    const buttonLabel = useMemo(() => {
        if (isUploading) return 'Subiendo...'
        if (conversionProgress !== null && conversionProgress !== 100) return 'Convirtiendo...'
        return 'Subir y Convertir Video'
    }, [isUploading, conversionProgress])

    return {
        videoFile,
        handleFileChange,
        handleUpload,
        uploadProgress,
        conversionProgress,
        isUploading,
        message,
        buttonLabel
    }
}

export default useVideoUpload
