import { FC } from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import { strings } from '../../constants/strings'
import { flexLogoProps, flexProps, useHeaderStyles } from '../../constants/styles/headerStyles'
import useHeader from '../../hooks/useHeader'
import HamburguerMenu from './HamburguerMenu'
import ThemeSelector from './ThemeSelector'

const Header: FC = () => {
    const { toast, username, handleLogout, navigate, handleNavigation } = useHeader()
    const { boxProps, headingProps } = useHeaderStyles()

    // const showThemeSelector = useBreakpointValue({ base: false, md: true })

    return (
        <Box {...boxProps}>
            <Flex {...flexProps} align="center">
                {/* Logo */}
                <Flex {...flexLogoProps}>
                    <Heading {...headingProps} onClick={() => navigate('/movies')}>
                        {strings.appName}
                    </Heading>
                </Flex>

                <ThemeSelector />

                {/* Menú Hamburguesa con saludo */}
                <HamburguerMenu
                    handleNavigation={handleNavigation}
                    handleLogout={handleLogout}
                    toast={toast}
                    username={username}
                />
            </Flex>
        </Box>
    )
}

export default Header
