import { create } from 'zustand'

export type ThemeType = {
    MAIN_BG: string
    CARD_BG: string
    HEADER_BG: string
    ACCENT: string
    TEXT: string
}

export type ThemeName =
    | 'main'
    | 'yellow_night'
    | 'cyberpunk'
    | 'horror'
    | 'synthwave'
    | 'cyber_neon'
    | 'deep_blue'
    | 'neo_tokyo'
    | 'vintage_sepia'

const themes: Record<ThemeName, ThemeType> = {
    main: {
        MAIN_BG: '#121417',
        HEADER_BG: '#0A0B0D',
        CARD_BG: '#1E2125',
        ACCENT: '#F56565',
        TEXT: '#E2E8F0'
    },
    yellow_night: {
        MAIN_BG: '#222222',
        CARD_BG: '#333333',
        HEADER_BG: '#444444',
        ACCENT: '#FFD700',
        TEXT: '#FFFACD'
    },
    cyberpunk: {
        MAIN_BG: '#0A192F',
        CARD_BG: '#112240',
        HEADER_BG: '#020C1B',
        ACCENT: '#64FFDA',
        TEXT: '#E6E6E6'
    },
    horror: {
        MAIN_BG: '#000000',
        CARD_BG: '#1B1B1B',
        HEADER_BG: '#220000',
        ACCENT: '#D72323',
        TEXT: '#F5F5DC'
    },
    synthwave: {
        MAIN_BG: '#1A1A2E',
        CARD_BG: '#16213E',
        HEADER_BG: '#0E0E1E',
        ACCENT: '#E94560',
        TEXT: '#F8D7FF'
    },
    cyber_neon: {
        MAIN_BG: '#0D1117',
        CARD_BG: '#090D13',
        HEADER_BG: '#161B22',
        ACCENT: '#00FFA3',
        TEXT: '#F8F8F2'
    },
    deep_blue: {
        MAIN_BG: '#0C0F14',
        CARD_BG: '#070A0E',
        HEADER_BG: '#1B2029',
        ACCENT: '#3B82F6',
        TEXT: '#F1F5F9'
    },
    neo_tokyo: {
        MAIN_BG: '#0B0C10',
        CARD_BG: '#1F2833',
        HEADER_BG: '#0A0B0D',
        ACCENT: '#C3073F',
        TEXT: '#66FCF1'
    },
    vintage_sepia: {
        MAIN_BG: '#2E2A25',
        CARD_BG: '#4B4237',
        HEADER_BG: '#3A332D',
        ACCENT: '#DAA520',
        TEXT: '#F5DEB3'
    }
}

const storedThemeName = (localStorage.getItem('themeName') as ThemeName) || 'main'

export const useThemeStore = create<{
    theme: ThemeType
    themeName: ThemeName
    setTheme: (name: ThemeName) => void
    allThemes: Record<ThemeName, ThemeType>
}>(set => ({
    allThemes: themes,
    theme: themes[storedThemeName],
    themeName: storedThemeName,
    setTheme: name => {
        localStorage.setItem('themeName', name)
        set(() => ({
            theme: themes[name],
            themeName: name
        }))
    }
}))
