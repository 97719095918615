import axios from 'axios'
import { API_USER_URL } from '../api/urls'
import { UseToastOptions, ToastId } from '@chakra-ui/react'
import { getUsernameFromToken } from '../utils/getUsernameFromToken'

type ToastFunction = (options?: UseToastOptions) => ToastId

/**
 * Logs out the user
 * @param {ToastFunction} toast - The toast function
 * @returns {Promise<void>} The promise
 */
export const logout = async (toast: ToastFunction): Promise<void> => {
    try {
        const token = localStorage.getItem('authToken')
        await axios.post(
            `${API_USER_URL}/logout`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )

        localStorage.removeItem('authToken')

        toast({
            title: 'Logout successful.',
            status: 'success',
            duration: 3000,
            isClosable: true
        })
    } catch (error) {
        toast({
            title: 'Logout failed.',
            description: 'An error occurred while logging out.',
            status: 'error',
            duration: 3000,
            isClosable: true
        })
    }
}

/**
 * Logs in the user
 * @param {string} username - Username
 * @param {string} password - Password
 * @param {ToastFunction} toast - The toast function
 * @returns {Promise<{accessToken: string, usernameFromToken: string}>} The promise
 */

export const login = async (
    username: string,
    password: string,
    toast: ToastFunction
): Promise<{ accessToken: string; usernameFromToken: string }> => {
    try {
        const response = await axios.post(`${API_USER_URL}/login`, {
            username,
            password
        })

        const { accessToken } = response.data
        const usernameFromToken = getUsernameFromToken(accessToken)

        if (usernameFromToken) {
            localStorage.setItem('authToken', accessToken)
            localStorage.setItem('username', usernameFromToken)
            toast({
                title: 'Inicio de sesión exitoso.',
                status: 'success',
                duration: 3000,
                isClosable: true
            })
        } else {
            throw new Error('Invalid token format')
        }

        return { accessToken, usernameFromToken }
    } catch (error: any) {
        toast({
            title: 'Inicio de sesión fallido.',
            description: error.response?.data?.message || 'Unexpected error occurred',
            status: 'error',
            duration: 3000,
            isClosable: true
        })

        throw error
    }
}

/**
 * Registra un nuevo usuario
 * @param {string} username - Nombre de usuario
 * @param {string} password - Contraseña
 * @param {string} superkey - Clave especial requerida para el registro
 * @param {ToastFunction} toast - Función de notificación
 * @returns {Promise<void>}
 */
export const register = async (
    username: string,
    email: string,
    password: string,
    superkey: string,
    toast: ToastFunction
): Promise<void> => {
    try {
        await axios.post(`${API_USER_URL}/register`, {
            username,
            password,
            email,
            superkey
        })

        toast({
            title: 'Usuario registrado correctamente.',
            status: 'success',
            duration: 3000,
            isClosable: true
        })
    } catch (error: any) {
        toast({
            title: 'Registro fallido.',
            description: error.response?.data?.message || 'Ocurrió un error inesperado.',
            status: 'error',
            duration: 3000,
            isClosable: true
        })

        throw error
    }
}

/**
 * Fetches all users
 * @param {string} token - The token
 * @returns {Promise<User[]>} The promise
 */
export const getUserById = async (id: string) => {
    const response = await axios.get(`${API_USER_URL}/${id}`)
    return response.data
}

/**
 * Updates a user
 * @param {string} id - The user ID
 * @param {object} data - The user data
 * @returns {Promise<User>} The updated user
 */
export const updateUser = async (
    id: string,
    data: { username?: string; email?: string; password?: string },
    toast: ToastFunction
): Promise<void> => {
    try {
        await axios.put(`${API_USER_URL}/${id}`, data)
        toast({
            title: 'Usuario actualizado correctamente.',
            status: 'success',
            duration: 3000,
            isClosable: true
        })
    } catch (error: any) {
        toast({
            title: 'Error al actualizar.',
            description: error.response?.data?.message || 'Ocurrió un error inesperado.',
            status: 'error',
            duration: 3000,
            isClosable: true
        })
        throw error
    }
}

/**
 * Deletes a user
 * @param {string} id - The user ID
 * @returns {Promise<User>} The deleted user
 */
export const deleteUser = async (id: string, toast: ToastFunction): Promise<void> => {
    try {
        await axios.delete(`${API_USER_URL}/${id}`)
        toast({
            title: 'Usuario eliminado correctamente.',
            status: 'success',
            duration: 3000,
            isClosable: true
        })
    } catch (error: any) {
        toast({
            title: 'Error al eliminar.',
            description: error.response?.data?.message || 'Ocurrió un error inesperado.',
            status: 'error',
            duration: 3000,
            isClosable: true
        })
        throw error
    }
}
