import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
    isAuthenticated: boolean
    isAdmin: boolean
    children: ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated, isAdmin, children }) => {
    if (!isAuthenticated || !isAdmin) {
        return <Navigate to="/" replace />
    }

    return <>{children}</>
}

export default ProtectedRoute
