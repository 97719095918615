import {
    Box,
    Input,
    Button,
    Heading,
    VStack,
    InputGroup,
    InputRightElement,
    IconButton,
    Card,
    CardBody,
    Text,
    HStack,
    CloseButton,
    useClipboard,
    FormControl,
    FormErrorMessage
} from '@chakra-ui/react'
import { useState } from 'react'
import { FiCopy, FiKey } from 'react-icons/fi'
import { register } from '../../api/authService'
import { useToast } from '@chakra-ui/react'

import SpinnerCentered from '../Common/SpinnerCentered'
import { useLoginStyles } from '../../constants/styles/loginStyles'
import { generateSecurePassword } from '../../utils/generateSecurePassword'

const Register: React.FC = () => {
    const { boxProps, headingProps, buttonProps, iconButtonProps } = useLoginStyles()
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [superkey, setSuperkey] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [createdUser, setCreatedUser] = useState<{ username: string; password: string } | null>(
        null
    )
    const [isEmailValid, setIsEmailValid] = useState(true)

    const toast = useToast()
    const togglePasswordVisibility = () => setShowPassword(!showPassword)

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    const cleanForm = () => {
        setUsername('')
        setEmail('')
        setPassword('')
        setSuperkey('')
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!validateEmail(email)) {
            setIsEmailValid(false)
            return
        }

        setIsLoading(true)
        try {
            await register(username, email, password, superkey, toast)
            setCreatedUser({ username, password })
            cleanForm()
        } catch (error) {
            console.error('Error en el registro:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box {...boxProps}>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <Heading {...headingProps}>Registro de Usuario</Heading>
                    <Input
                        placeholder="Usuario"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />

                    <FormControl isInvalid={!isEmailValid}>
                        <Input
                            placeholder="Email"
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value)
                                setIsEmailValid(true)
                            }}
                        />
                        {!isEmailValid && <FormErrorMessage>Email no válido</FormErrorMessage>}
                    </FormControl>

                    <InputGroup>
                        <Input
                            placeholder="Contraseña"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <InputRightElement width="6rem">
                            <HStack spacing={1}>
                                <IconButton
                                    aria-label="Generar contraseña"
                                    icon={<FiKey />}
                                    size="sm"
                                    onClick={() => generateSecurePassword(setPassword)}
                                    variant="ghost"
                                />
                            </HStack>
                        </InputRightElement>
                        <InputRightElement>
                            <IconButton
                                {...iconButtonProps(showPassword)}
                                onClick={togglePasswordVisibility}
                            />
                        </InputRightElement>
                    </InputGroup>

                    <Input
                        placeholder="Superkey"
                        type="password"
                        value={superkey}
                        onChange={e => setSuperkey(e.target.value)}
                    />

                    <Button {...buttonProps} isLoading={isLoading}>
                        {isLoading ? <SpinnerCentered /> : 'Registrar'}
                    </Button>
                </VStack>
            </form>

            {createdUser && (
                <UserInfoCard createdUser={createdUser} onClose={() => setCreatedUser(null)} />
            )}
        </Box>
    )
}

interface UserInfoCardProps {
    createdUser: { username: string; password: string }
    onClose: () => void
}

const UserInfoCard: React.FC<UserInfoCardProps> = ({ createdUser, onClose }) => {
    const { hasCopied, onCopy } = useClipboard(
        `Usuario: ${createdUser.username}\nContraseña: ${createdUser.password}`
    )

    return (
        <Card
            mt={6}
            p={4}
            bg="gray.800"
            borderRadius="md"
            border="1px solid rgba(255, 255, 255, 0.2)"
        >
            <CardBody>
                <HStack justifyContent="space-between">
                    <Heading fontSize="lg" color="white">
                        Usuario Creado
                    </Heading>
                    <CloseButton onClick={onClose} />
                </HStack>
                <Text mt={2} color="gray.300">
                    <strong>Usuario:</strong> {createdUser.username}
                </Text>
                <Text color="gray.300">
                    <strong>Contraseña:</strong> {createdUser.password}
                </Text>
                <Button
                    leftIcon={<FiCopy />}
                    mt={3}
                    colorScheme={hasCopied ? 'green' : 'blue'}
                    onClick={onCopy}
                    size="sm"
                >
                    {hasCopied ? 'Copiado!' : 'Copiar'}
                </Button>
            </CardBody>
        </Card>
    )
}

export default Register
