import { useState, useEffect } from 'react'
import { Button, ButtonGroup, Flex, Icon, useBreakpointValue } from '@chakra-ui/react'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { usePaginatorStyles } from '../../constants/styles/paginatorStyles'

interface PaginatorProps {
    currentPage: number
    totalPages: number
    onNext: () => void
    onPrevious: () => void
    onFirstPage: () => void
    onLastPage: () => void
}

const Paginator: React.FC<PaginatorProps> = ({
    currentPage,
    totalPages,
    onNext,
    onPrevious,
    onFirstPage,
    onLastPage
}) => {
    const positionProps = useBreakpointValue({
        base: { bottom: '0', borderRadius: '12px 12px 0 0' },
        md: { top: '0', borderRadius: '0 0 12px 12px' }
    })

    const { containerStyles, buttonStyles, disabledButtonStyles } = usePaginatorStyles()

    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (positionProps) {
            setIsReady(true)
        }
    }, [positionProps])

    if (!isReady) return null

    return (
        <Flex {...containerStyles} {...positionProps}>
            <ButtonGroup spacing={4} alignItems="center">
                <Button
                    {...(currentPage < 3 ? disabledButtonStyles : buttonStyles)}
                    onClick={onFirstPage}
                >
                    <Icon as={FiChevronsLeft} boxSize={5} />
                </Button>

                <Button
                    {...(currentPage === 1 ? disabledButtonStyles : buttonStyles)}
                    onClick={onPrevious}
                >
                    <Icon as={FiChevronLeft} boxSize={5} />
                </Button>

                <Button
                    {...(currentPage === totalPages ? disabledButtonStyles : buttonStyles)}
                    onClick={onNext}
                >
                    <Icon as={FiChevronRight} boxSize={5} />
                </Button>

                <Button
                    {...(currentPage >= totalPages - 1 ? disabledButtonStyles : buttonStyles)}
                    onClick={onLastPage}
                >
                    <Icon as={FiChevronsRight} boxSize={5} />
                </Button>
            </ButtonGroup>
        </Flex>
    )
}

export default Paginator
