import { TooltipProps, BoxProps, TextProps, useTheme } from '@chakra-ui/react'

interface MovieTooltipStyles {
    tooltipProps: TooltipProps
    tooltipBoxProps: BoxProps
    titleTextProps: TextProps
    descriptionTextProps: TextProps
}

export const useMovieTooltipStyles = (): MovieTooltipStyles => {
    const theme = useTheme()

    return {
        tooltipProps: {
            children: null,
            placement: 'auto',
            bg: theme.colors.brand.card,
            color: 'white',
            p: 5,
            borderRadius: 'lg',
            border: `2px solid ${theme.colors.brand.accent}`,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            maxW: '340px',
            whiteSpace: 'normal',
            openDelay: 500,
            closeDelay: 100
        },
        tooltipBoxProps: {
            p: 4
        },
        titleTextProps: {
            fontSize: '23px',
            fontWeight: 'bold',
            color: theme.colors.brand.accent
        },
        descriptionTextProps: {
            fontSize: '22px',
            color: '#cbd5e0',
            noOfLines: 5
        }
    }
}
