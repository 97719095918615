import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    useTheme,
    useClipboard,
    HStack
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { User } from '../../hooks/useAdminUsers'
import { FiKey, FiCopy, FiCheck } from 'react-icons/fi'
import { generateSecurePassword } from '../../utils/generateSecurePassword'

interface Props {
    isOpen: boolean
    onClose: () => void
    user: User | null
    onSave: (data: { username?: string; email?: string; password?: string }) => void
}

const AdminModalEdit = ({ isOpen, onClose, user, onSave }: Props) => {
    const theme = useTheme()
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { hasCopied, onCopy } = useClipboard(password)

    useEffect(() => {
        if (user) {
            setUsername(user.username || '')
            setEmail(user.email || '')
            setPassword('')
        } else {
            setUsername('')
            setEmail('')
            setPassword('')
        }
    }, [user])

    const handleSave = () => {
        if (user) {
            const data = password ? { username, email, password } : { username, email }
            onSave(data)
        }
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bg={theme.colors.brand.card} color={theme.colors.brand.text}>
                <ModalHeader color={theme.colors.brand.accent}>Editar Usuario</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Username</FormLabel>
                        <Input value={username} onChange={e => setUsername(e.target.value)} />
                    </FormControl>

                    <FormControl mb={4}>
                        <FormLabel>Email</FormLabel>
                        <Input value={email} onChange={e => setEmail(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Nueva contraseña</FormLabel>
                        <InputGroup>
                            <Input
                                placeholder="Dejar vacío para no modificar"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                pr="5.5rem"
                            />
                            <InputRightElement width="5.5rem">
                                <HStack spacing={1} justify="flex-end">
                                    <IconButton
                                        size="sm"
                                        aria-label="Generar contraseña"
                                        icon={<FiKey />}
                                        onClick={() => generateSecurePassword(setPassword)}
                                        variant="ghost"
                                        _hover={{ bg: 'transparent' }}
                                    />
                                    <IconButton
                                        size="sm"
                                        aria-label={hasCopied ? 'Copiado' : 'Copiar contraseña'}
                                        icon={hasCopied ? <FiCheck /> : <FiCopy />}
                                        onClick={onCopy}
                                        variant="ghost"
                                        colorScheme={hasCopied ? 'green' : undefined}
                                        _hover={{ bg: 'transparent' }}
                                    />
                                </HStack>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button colorScheme="blue" onClick={handleSave}>
                        Guardar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default AdminModalEdit
