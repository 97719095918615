import { VStack, HStack, Badge, Text } from '@chakra-ui/react'

interface Rating {
    Source: string
    Value: string
}

interface MovieRatingsProps {
    ratings: Rating[] | undefined
}

const MovieRatings = ({ ratings }: MovieRatingsProps) => {
    if (!ratings || ratings.length === 0) {
        return null
    }

    return (
        <VStack align="start" spacing={2} mt={4}>
            {ratings.map((rating, index) => {
                const [score, maxScore] = rating.Value.includes('/')
                    ? rating.Value.split('/')
                    : [rating.Value, null]

                return (
                    <HStack key={index} spacing={3} align="center">
                        <Badge colorScheme="purple" fontSize="md" p={1} borderRadius="md">
                            {rating.Source}
                        </Badge>

                        <HStack spacing={1} align="center">
                            <Text fontSize="lg" fontWeight="bold" color="yellow.300">
                                {score}
                            </Text>

                            {maxScore && (
                                <>
                                    <Text fontSize="sm" color="gray.400">
                                        /
                                    </Text>
                                    <Text fontSize="lg" fontWeight="bold" color="yellow.300">
                                        {maxScore}
                                    </Text>
                                </>
                            )}
                        </HStack>
                    </HStack>
                )
            })}
        </VStack>
    )
}

export default MovieRatings
