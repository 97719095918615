import { TextProps } from '@chakra-ui/react'

export const textProps: TextProps = {
    fontSize: 'xl',
    color: '',
    fontStyle: 'italic'
}

export const highlightedTextProps: TextProps = {
    fontStyle: 'italic',
    fontSize: 'xl',
    fontWeight: 'bold',
    color: 'gray.400',
    textShadow: '0px 0px 3px rgba(50, 50, 50, 0.5)',
    marginBottom: '10px'
}
