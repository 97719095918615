import { getContrastColor } from './../../utils/getContrastColor'
import { BadgeProps, useTheme } from '@chakra-ui/react'

export const useBadgeStyles = (): BadgeProps => {
    const theme = useTheme()

    return {
        bg: theme.colors.brand.accent,
        color: getContrastColor(theme.colors.brand.text),
        px: 2,
        py: 1,
        borderRadius: 'md',
        fontSize: 'sm',
        boxShadow: 'lg',
        zIndex: 2
    }
}
