import { useEffect, useState } from 'react'
import { fetchAdminUsers, disableUser } from '../api/adminService'
import { deleteUser, updateUser } from '../api/authService'
import { useToast } from '@chakra-ui/react'

export interface User {
    _id: string
    username: string
    email: string
    isActive: boolean
}

export const useAdminUsers = () => {
    const [users, setUsers] = useState<User[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const toast = useToast()

    const ITEMS_PER_PAGE = 15
    const totalUsers = users.length
    const activeUsers = users.filter(u => u.isActive).length
    const inactiveUsers = totalUsers - activeUsers
    const totalPages = Math.ceil(totalUsers / ITEMS_PER_PAGE)

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const visibleUsers = users.slice(startIndex, startIndex + ITEMS_PER_PAGE)

    useEffect(() => {
        fetchAdminUsers().then(setUsers)
    }, [])

    const handleDisable = async (id: string) => {
        try {
            await disableUser(id)
            const updated = users.map(u => (u._id === id ? { ...u, isActive: !u.isActive } : u))
            setUsers(updated)

            toast({
                title: updated.find(u => u._id === id)?.isActive
                    ? 'Usuario habilitado.'
                    : 'Usuario desactivado.',
                status: 'success',
                duration: 3000,
                isClosable: true
            })
        } catch {
            toast({
                title: 'Error al actualizar estado.',
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        }
    }

    const handleDelete = async (id: string) => {
        try {
            await deleteUser(id, toast)
            setUsers(prev => prev.filter(u => u._id !== id))
        } catch {}
    }

    const handleUpdate = async (id: string, data: { username?: string; email?: string }) => {
        try {
            await updateUser(id, data, toast)
            setUsers(prev => prev.map(u => (u._id === id ? { ...u, ...data } : u)))
        } catch {}
    }

    const openEdit = (user: User) => {
        setSelectedUser(user)
        setIsEditOpen(true)
    }

    const openDelete = (user: User) => {
        setSelectedUser(user)
        setIsDeleteOpen(true)
    }

    const confirmDelete = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id)
            setIsDeleteOpen(false)
        }
    }

    const confirmUpdate = async (data: { username?: string; email?: string }) => {
        if (selectedUser) {
            await handleUpdate(selectedUser._id, data)
            setIsEditOpen(false)
        }
    }

    return {
        users,
        visibleUsers,
        currentPage,
        totalPages,
        activeUsers,
        inactiveUsers,
        totalUsers,
        setCurrentPage,
        handleDisable,
        handleDelete,
        handleUpdate,
        selectedUser,
        isEditOpen,
        isDeleteOpen,
        openEdit,
        openDelete,
        confirmDelete,
        confirmUpdate,
        setIsEditOpen,
        setIsDeleteOpen
    }
}
