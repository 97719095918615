import { getContrastColor } from './../../utils/getContrastColor'
import {
    useTheme,
    BoxProps,
    HeadingProps,
    IconButtonProps,
    ButtonProps,
    InputProps
} from '@chakra-ui/react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import React from 'react'

interface LoginStyles {
    boxProps: BoxProps
    headingProps: HeadingProps
    buttonProps: ButtonProps
    inputProps: InputProps
    iconButtonProps: (showPassword: boolean) => IconButtonProps
}

export const useLoginStyles = (): LoginStyles => {
    const theme = useTheme()

    return {
        boxProps: {
            p: 6,
            maxWidth: '400px',
            margin: 'auto',
            mt: '10%',
            bg: theme.colors.brand.card,
            borderRadius: 'lg',
            boxShadow: 'lg',
            border: `2px solid ${theme.colors.brand.accent}`
        },
        headingProps: {
            as: 'h1',
            size: 'lg',
            color: theme.colors.brand.accent,
            fontWeight: 'bold',
            fontFamily: 'Creepster, sans-serif',
            cursor: 'pointer',
            textAlign: 'center'
        },
        inputProps: {
            borderColor: theme.colors.brand.accent,
            color: theme.colors.brand.text,
            _placeholder: { color: 'gray.500' }
        },
        buttonProps: {
            type: 'submit',
            width: 'full',
            bg: theme.colors.brand.accent,
            color: getContrastColor(theme.colors.brand.text),
            _hover: { opacity: 0.8 }
        },
        iconButtonProps: (showPassword: boolean) => ({
            'aria-label': `${showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}`,
            icon: showPassword ? React.createElement(FaEyeSlash) : React.createElement(FaEye),
            variant: 'ghost',
            size: 'sm',
            color: theme.colors.brand.accent
        })
    }
}
