import { BoxProps, HeadingProps, TextProps, useTheme } from '@chakra-ui/react'

export const useMovieListStyles = () => {
    const theme = useTheme()

    const boxProps: BoxProps = {
        position: 'relative',
        bg: theme.colors.brand.mainBg,
        minH: '100vh',
        mt: '10px',
        p: [1, 4]
    }

    const headingProps: HeadingProps = {
        as: 'h2',
        size: 'lg',
        color: 'white',
        mb: 3,
        borderRadius: 'md',
        p: [1, 0]
    }

    const headingTextProps: TextProps = {
        as: 'span',
        color: theme.colors.brand.accent
    }

    return {
        boxProps,
        headingProps,
        headingTextProps
    }
}
