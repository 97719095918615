import { getContrastColor } from './../../utils/getContrastColor'
import { LinkProps, useTheme } from '@chakra-ui/react'
import {
    BadgeProps,
    BoxProps,
    ModalBodyProps,
    ModalCloseButtonProps,
    ModalContentProps,
    TextProps
} from '@chakra-ui/react'

export const useMovieDetailModalStyles = (poster: string | undefined) => {
    const theme = useTheme()

    const modalContentProps: ModalContentProps = {
        position: 'relative',
        bg: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        p: 4,
        maxW: ['95vw', '96vw', '95vw', '80vw'],
        maxH: ['95vh', '96vh', '90vh'],
        width: '100%',
        borderRadius: 'lg',
        overflow: 'hidden',
        transition: 'all 0.3s',
        _before: {
            content: `""`,
            position: 'absolute',
            inset: 0,
            backgroundImage: `url(${poster})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.4) blur(4px)',
            opacity: 0.9,
            zIndex: -2
        },
        _after: {
            content: `""`,
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.2))',
            backdropFilter: 'blur(6px)',
            zIndex: -1
        }
    }

    const modalCloseButtonProps: ModalCloseButtonProps = {
        color: 'white',
        top: '10px',
        right: '10px',
        zIndex: '2',
        _hover: {
            color: 'red.400',
            transform: 'scale(1.2)'
        }
    }

    const modalBodyProps: ModalBodyProps = {
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        p: 4,
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        transition: 'all 0.3s',
        maxH: '85vh'
    }

    const boxVideoProps: BoxProps = {
        flex: '3',
        mb: [4, 0],
        mr: [0, 8],
        width: '100%',
        position: 'relative'
    }

    const badgeTitleProps: BadgeProps = {
        size: 'lg',
        mb: 4,
        fontSize: ['lg', '1.5rem'],
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        color: getContrastColor(theme.colors.brand.text),
        bg: theme.colors.brand.accent,
        px: 4,
        py: 2,
        borderRadius: 'md',
        fontWeight: 'bold',
        style: {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2
        }
    }

    const imageModalProps: BoxProps = {
        borderRadius: 'md',
        boxSize: ['150px', '200px', '250px'],
        objectFit: 'cover',
        boxShadow: 'xl',
        border: '2px solid rgba(255, 255, 255, 0.2)'
    }

    const highlightedTextProps: TextProps = {
        fontStyle: 'italic',
        fontSize: 'xl',
        fontWeight: 'bold',
        color: 'gray.400',
        textShadow: '0px 0px 3px rgba(50, 50, 50, 0.5)',
        marginBottom: '10px'
    }

    const textProps: TextProps = {
        fontWeight: 'bold',
        fontSize: 'lg',
        color: 'white'
    }

    const trailerLinkProps: LinkProps = {
        display: 'inline-flex',
        alignItems: 'center',
        gap: 2,
        fontSize: 'xl',
        fontWeight: 'bold',
        color: theme.colors.brand.accent,
        mt: 3,
        transition: 'all 0.2s ease-out',
        _hover: {
            color: 'red.300',
            textDecoration: 'none',
            transform: 'scale(1.05) translateX(3px)'
        },
        _active: {
            color: 'red.500',
            transform: 'scale(0.95) translateX(0px)'
        }
    }

    return {
        modalContentProps,
        modalCloseButtonProps,
        modalBodyProps,
        boxVideoProps,
        badgeTitleProps,
        imageModalProps,
        highlightedTextProps,
        textProps,
        trailerLinkProps
    }
}
