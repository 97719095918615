import {
    HStack,
    IconButton,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack
} from '@chakra-ui/react'
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa'

interface VolumeControlProps {
    handleVolumeChange: (value: number) => void
    isMuted: boolean
    toggleMute: () => void
    volume: number
}

const VolumeControl: React.FC<VolumeControlProps> = ({
    handleVolumeChange,
    isMuted,
    toggleMute,
    volume
}) => (
    <HStack spacing={2}>
        <Slider
            aria-label="Volume slider"
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={handleVolumeChange}
            width="120px"
        >
            {/* Track: gris oscuro */}
            <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="whiteAlpha.900" />
            </SliderTrack>
            <SliderThumb
                boxSize={4}
                bg="whiteAlpha.900"
                border="2px solid rgba(255, 255, 255, 0.5)"
                borderRadius="full"
                transition="transform 0.2s ease"
            />
        </Slider>
        <IconButton
            aria-label="Mute/Unmute"
            icon={isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            onClick={toggleMute}
            color="whiteAlpha.900"
            variant="ghost"
            fontSize="25px"
        />
    </HStack>
)

export default VolumeControl
