import { useEffect } from 'react'
import { API_URL } from '../api/urls'
import { io } from 'socket.io-client'

const socket = io(API_URL, {
    transports: ['websocket', 'polling'],
    reconnectionAttempts: 5,
    timeout: 5000
})

const useCacheListener = () => {
    useEffect(() => {
        socket.on('connect', () => {
            console.log(`✅ Conectado a WebSocket. Socket ID: ${socket.id}`)
        })

        socket.on('cacheCleared', () => {
            console.log('🗑️ Cache de Redis limpiado. Eliminando cache local...')
            localStorage.removeItem('movie-storage')

            setTimeout(() => {
                window.location.reload()
            }, 500)
        })

        socket.on('disconnect', () => {
            console.log('🔴 Desconectado de WebSocket')
        })

        return () => {
            console.log('♻️ Cerrando conexión de WebSocket')
            socket.disconnect()
        }
    }, [])
}

export default useCacheListener
