/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { FileData, Movie } from '../interfaces/movie'
import { cleanTitle } from '../utils/cleanMovieTitle'
import { STREAM_URL } from '../api/urls'
import useFilterStore from '../stores/filterStore'
import useMovieStore from '../stores/movieStore'
import {
    fetchMovieFromDB,
    fetchMovies,
    fetchSubtitleText,
    getStoredOrFetchedMovieData,
    processSubtitleText
} from '../api/movieService'
import useAuthStore from '../stores/authStore'
import usePaginationStore from '../stores/usePaginationStore'
import useNetworkSpeed from './useNetworkSpeed'

const useMovieList = () => {
    const { onOpen, isOpen, onClose } = useDisclosure()
    const networkSpeed = useNetworkSpeed()

    const [currentMovieDetails, setCurrentMovieDetails] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingPlayer, setIsLoadingPlayer] = useState(false)

    const currentPage = usePaginationStore(state => state.currentPage)
    const totalPages = usePaginationStore(state => state.totalPages)
    const setCurrentPage = usePaginationStore(state => state.setCurrentPage)
    const setTotalPages = usePaginationStore(state => state.setTotalPages)

    const movies = useMovieStore(state => state.movies)
    const setMovies = useMovieStore(state => state.setMovies)
    const setSelectedMovie = useMovieStore(state => state.setSelectedMovie)
    const setCurrentVideoUrl = useMovieStore(state => state.setCurrentVideoUrl)
    const setCurrentSubtitleText = useMovieStore(state => state.setCurrentSubtitleText)
    const displayedMovies = useMovieStore(state => state.displayedMovies)
    const isModalReady = useMovieStore(state => state.isModalReady)
    const setDisplayedMovies = useMovieStore(state => state.setDisplayedMovies)
    const selectedMovie = useMovieStore(state => state.selectedMovie)
    const setIsModalReady = useMovieStore(state => state.setIsModalReady)
    const currentVideoUrl = useMovieStore(state => state.currentVideoUrl)
    const currentSubtitleText = useMovieStore(state => state.currentSubtitleText)
    const moviePosters = useMovieStore(state => state.moviePosters)
    const setMoviePosters = useMovieStore(state => state.setMoviePosters)
    const movieDetails = useMovieStore(state => state.movieDetails)
    const setMovieDetails = useMovieStore(state => state.setMovieDetails)

    const searchQuery = useFilterStore(state => state.searchQuery)
    const username = useAuthStore(state => state.username)

    const fetchMoviesFromApi = useCallback(async (page = 1) => {
        setIsLoading(true)
        try {
            const { movies, totalPages } = await fetchMovies(username, page)

            setMovies(movies)
            setDisplayedMovies(movies)
            setTotalPages(totalPages)
            setCurrentPage(page)
        } finally {
            setIsLoading(false)
        }
    }, [])

    const handleOpenPlayer = async (
        year: string,
        title: string,
        file: FileData,
        subtitle?: FileData
    ) => {
        setSelectedMovie({ title, year })
        setIsLoadingPlayer(true)

        const videoUrl = STREAM_URL(year, title, file.name, username, networkSpeed)
        setCurrentVideoUrl(videoUrl)

        const movieDetails = await fetchMovieData(title, year, 'details')
        setCurrentMovieDetails(movieDetails)

        try {
            const movieData = await fetchMovieFromDB(title, username)

            let subtitleText = null

            if (movieData?.subtitleText) {
                try {
                    subtitleText = await processSubtitleText(movieData.subtitleText, title)
                } catch (processError) {
                    if (subtitle) {
                        console.log('🔄 Fetching subtitle from file...')
                        subtitleText = await fetchSubtitleText(year, title, subtitle.name, username)

                        if (subtitleText) {
                            subtitleText = await processSubtitleText(subtitleText, title)
                        }
                    }
                }
            } else if (subtitle) {
                subtitleText = await fetchSubtitleText(year, title, subtitle.name, username)

                if (subtitleText) {
                    subtitleText = await processSubtitleText(subtitleText, title)
                }
            }

            setCurrentSubtitleText(subtitleText)
        } catch (error) {
            setCurrentSubtitleText(null)
        }

        setIsLoadingPlayer(false)
        onOpen()
    }

    const fetchMovieData = useCallback(
        async (title: string, year: string, dataType: 'poster' | 'details') => {
            const key = `${cleanTitle(title)}_${year}_${dataType}`
            const cache = dataType === 'poster' ? moviePosters[key] : movieDetails[key]

            if (cache) return cache

            const result = await getStoredOrFetchedMovieData(title, year, dataType)
            if (!result) {
                console.warn(`⚠️ ${dataType} not found for movie: ${title} (${year})`)
                return null
            }

            return result.data
        },
        [moviePosters, setMoviePosters, movieDetails, setMovieDetails]
    )

    useEffect(() => {
        fetchMoviesFromApi(currentPage)
    }, [currentPage])

    useEffect(() => {
        movies.forEach((movie: Movie) => {
            fetchMovieData(movie.title, movie.year, 'poster')
            fetchMovieData(movie.title, movie.year, 'details')
        })
    }, [movies])

    return {
        movies,
        handleOpenPlayer,
        movieDetails,
        currentMovieDetails,
        isLoading,
        isLoadingPlayer,
        isOpen,
        onClose,
        displayedMovies,
        isModalReady,
        setDisplayedMovies,
        selectedMovie,
        setIsModalReady,
        currentVideoUrl,
        currentSubtitleText,
        moviePosters,
        searchQuery,
        currentPage,
        totalPages,
        setCurrentPage
    }
}

export default useMovieList
