import { BrowserRouter as Router } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import Header from './components/Common/Header'
import Login from './components/User/Login'
import useSession from './hooks/useSession'
import { Analytics } from '@vercel/analytics/react'
import AppContent from './components/Common/AppContent'
import useCacheListener from './hooks/useCacheListener'

const App = () => {
    const { isAuthenticated, username } = useSession()

    useCacheListener()

    return (
        <Router>
            <Box width="100%">
                {isAuthenticated ? (
                    <>
                        <Header />
                        <AppContent isAuthenticated={isAuthenticated} username={username} />
                    </>
                ) : (
                    <Login />
                )}
            </Box>
            <Analytics />
        </Router>
    )
}

export default App
