import { useEffect, useState } from 'react'
import { translateToSpanish, fetchMovieTrailer } from '../api/externalService'

interface Rating {
    Source: string
    Value: string
}

export interface MovieDetails {
    Title?: string
    Year?: string
    Genre?: string
    Plot?: string
    Director?: string
    Actors?: string
    Poster?: string
    Runtime?: string
    Ratings?: Rating[]
    TrailerUrl?: string
    trailerUrl?: string
    TranslatedPlot?: string
}

const useMovieDetailModal = (movieDetails: MovieDetails, onLoaded: () => void) => {
    const { Title, Year, Poster, Plot, TrailerUrl, trailerUrl } = movieDetails || {}
    const [translatedPlot, setTranslatedPlot] = useState<string | null>(null)
    const [trailerURI, setTrailerURI] = useState<string | null>(null)

    useEffect(() => {
        onLoaded()

        if (!Plot) return

        const cacheKey = `translation_${Title}_${Year}`
        const cachedTranslation = localStorage.getItem(cacheKey)

        if (cachedTranslation) {
            setTranslatedPlot(cachedTranslation)
        } else {
            const fetchTranslation = async () => {
                try {
                    const translation = await translateToSpanish(Plot)
                    const finalTranslation = translation || Plot
                    setTranslatedPlot(finalTranslation)

                    localStorage.setItem(cacheKey, finalTranslation)
                } catch (error) {
                    console.error(`❌ Error translating plot for ${Title}:`, error)
                    setTranslatedPlot(Plot)
                }
            }
            fetchTranslation()
        }

        return () => {
            setTranslatedPlot(null)
        }
    }, [Plot, Title, Year, onLoaded])

    useEffect(() => {
        if (!Title || !Year) return

        const handledTrailerUrl = trailerUrl || TrailerUrl

        if (handledTrailerUrl) return setTrailerURI(handledTrailerUrl)

        const fetchTrailer = async () => {
            const trailer = await fetchMovieTrailer(Year, Title)
            setTrailerURI(trailer)
        }

        fetchTrailer()
    }, [Title, Year, TrailerUrl, trailerUrl])

    return {
        Title,
        Year,
        Poster,
        translatedPlot,
        trailerUrl: trailerURI
    }
}

export default useMovieDetailModal
