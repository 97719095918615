import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Box } from '@chakra-ui/react'
import { FaBars, FaSignOutAlt } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { strings } from '../../constants/strings'
import { isAdminUser } from '../../utils/isAdminUser'
import { useHamburguerMenuStyles } from '../../constants/styles/hamburguerStyles'

interface HamburguerMenuProps {
    handleNavigation: (path: string) => void
    handleLogout: (toast: any) => void
    toast: any
    username: string
}

const HamburguerMenu: React.FC<HamburguerMenuProps> = ({
    handleNavigation,
    handleLogout,
    toast,
    username
}) => {
    const location = useLocation()
    const isAdmin = isAdminUser(username)
    const { menuButtonProps, menuListProps, menuItemProps, saluteBoxProps } =
        useHamburguerMenuStyles()

    return (
        <Flex align="center">
            <Menu>
                <MenuButton as={IconButton} icon={<FaBars />} {...menuButtonProps} />
                <MenuList {...menuListProps}>
                    <Box {...saluteBoxProps}>
                        {strings.salute}{' '}
                        <Text as="span" color="brand.accent" fontSize="lg">
                            {username}
                        </Text>
                    </Box>
                    {/* Opciones */}
                    <MenuItem
                        onClick={() => handleNavigation('/movies')}
                        {...menuItemProps}
                        bg={location.pathname === '/movies' ? 'brand.card' : 'brand.header'}
                    >
                        {strings.movies}
                    </MenuItem>

                    {isAdmin && (
                        <MenuItem
                            onClick={() => handleNavigation('/register')}
                            {...menuItemProps}
                            bg={location.pathname === '/register' ? 'brand.card' : 'brand.header'}
                        >
                            {'Registrar usuario '}
                        </MenuItem>
                    )}
                    {isAdmin && (
                        <MenuItem
                            onClick={() => handleNavigation('/admin')}
                            {...menuItemProps}
                            bg={location.pathname === '/admin' ? 'brand.card' : 'brand.header'}
                        >
                            {'Admin Dashboard'}
                        </MenuItem>
                    )}
                    {isAdmin && (
                        <MenuItem
                            onClick={() => handleNavigation('/upload')}
                            {...menuItemProps}
                            bg={location.pathname === '/upload' ? 'brand.card' : 'brand.header'}
                        >
                            {'Convertir Video'}
                        </MenuItem>
                    )}
                    {/* Logout */}
                    <MenuItem onClick={() => handleLogout(toast)} {...menuItemProps}>
                        <FaSignOutAlt style={{ marginRight: '8px' }} />
                        {strings.logout}
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}

export default HamburguerMenu
