import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { toBase64 } from '../utils/toBase64'
import { fetchMovieProgress, saveMovieProgress } from '../api/movieService'

interface UseVideoPlayerProps {
    subtitleText?: string | null
    title: string | undefined
}

const useVideoPlayer = ({ subtitleText, title }: UseVideoPlayerProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const hideControlsTimeout = useRef<NodeJS.Timeout | null>(null)
    const videoRef = useRef<ReactPlayer>(null)
    const savedProgressRef = useRef<number>(0)
    const hasMountedRef = useRef(false)
    const subtitleUrl = subtitleText ? `data:text/vtt;base64,${toBase64(subtitleText)}` : null

    const [areSubtitlesVisible, setAreSubtitlesVisible] = useState(true)
    const [duration, setDuration] = useState(0)
    const [hoverTime, setHoverTime] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isMuted, setIsMuted] = useState(false)
    const [isPlaying, setIsPlaying] = useState(true)
    const [progress, setProgress] = useState<number | null>(null)
    const [showControls, setShowControls] = useState(true)
    const [volume, setVolume] = useState(0.5)

    const username = localStorage.getItem('username')
    const yearMatch = title?.match(/\((\d{4})\)/)
    const year = yearMatch ? yearMatch[1] : null

    const currentTime = (progress ?? 0) * duration
    const remainingTime = duration - currentTime

    const resetHideControlsTimeout = () => {
        if (hideControlsTimeout.current) {
            clearTimeout(hideControlsTimeout.current)
        }
        hideControlsTimeout.current = setTimeout(() => {
            setShowControls(false)
        }, 3000)
    }

    const handleMouseMove = () => {
        setShowControls(true)
        resetHideControlsTimeout()
    }

    const togglePlayPause = (playState?: boolean) => {
        setIsPlaying(playState !== undefined ? playState : !isPlaying)
    }

    const handleVolumeChange = (value: number) => {
        setVolume(value)
        setIsMuted(value === 0)
    }

    const toggleMute = () => {
        setIsMuted(!isMuted)
    }

    const handleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        } else if (containerRef.current) {
            containerRef.current.requestFullscreen()
        }
    }

    const skipForward = (seconds: number) => {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime + seconds)
        }
    }

    const skipBackward = (seconds: number) => {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime - seconds)
        }
    }

    const handleProgress = (state: { played: number }) => {
        if (progress === null) return
        setProgress(state.played)
        savedProgressRef.current = state.played
    }

    const handleDuration = (videoDuration: number) => {
        setDuration(videoDuration)
    }

    const handleSliderHover = (event: React.MouseEvent<HTMLDivElement>, sliderWidth: number) => {
        const hoverPosition = event.clientX - event.currentTarget.getBoundingClientRect().left
        const hoverPercentage = hoverPosition / sliderWidth
        setHoverTime(hoverPercentage * duration)
    }

    const toggleSubtitles = () => {
        setAreSubtitlesVisible(!areSubtitlesVisible)
    }

    const clearHoverTime = () => {
        setHoverTime(null)
    }

    useEffect(() => {
        const loadProgress = async () => {
            const savedProgress = await fetchMovieProgress(username, title, year || '')
            setProgress(savedProgress.progress)
            setIsMuted(savedProgress.isMuted)
            savedProgressRef.current = savedProgress.progress
            if (videoRef.current) {
                videoRef.current.seekTo(savedProgress.progress, 'fraction')
            }
        }

        loadProgress()
    }, [username, title, year])

    useEffect(() => {
        return () => {
            saveMovieProgress(username, title, year || '', savedProgressRef.current, isMuted)
        }
    }, [username, title, year, isMuted])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            switch (event.code) {
                case 'Space':
                    event.preventDefault()
                    togglePlayPause()
                    break
                case 'ArrowRight':
                    event.preventDefault()
                    skipForward(10)
                    break
                case 'ArrowLeft':
                    event.preventDefault()
                    skipBackward(10)
                    break
                case 'KeyM':
                    event.preventDefault()
                    toggleMute()
                    break
                case 'KeyF':
                    event.preventDefault()
                    handleFullScreen()
                    break
                default:
                    break
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [isPlaying, videoRef, isMuted])

    useEffect(() => {
        if (!hasMountedRef.current) {
            hasMountedRef.current = true
            return
        }

        return () => {
            saveMovieProgress(username, title, year || '', savedProgressRef.current, isMuted)
        }
    }, [username, title, year, isMuted])

    return {
        areSubtitlesVisible,
        clearHoverTime,
        containerRef,
        duration,
        handleDuration,
        handleFullScreen,
        handleMouseMove,
        handleProgress,
        handleSeekChange: (value: number) => {
            if (videoRef.current) {
                videoRef.current.seekTo(value)
                setProgress(value)
                savedProgressRef.current = value
            }
        },
        handleSliderHover,
        handleVolumeChange,
        hoverTime,
        isLoading,
        isMuted,
        isPlaying,
        progress,
        remainingTime,
        setIsLoading,
        setIsPlaying,
        showControls,
        skipBackward,
        skipForward,
        subtitleUrl,
        toggleMute,
        togglePlayPause,
        toggleSubtitles,
        videoRef,
        volume
    }
}

export default useVideoPlayer
