import api from './axiosConfig'
import { API_URL } from './urls'

export const fetchAdminUsers = async () => {
    const response = await api.get(`${API_URL}/api/admin/users`)
    return response.data
}

export const fetchActivityLogs = async () => {
    const response = await api.get(`${API_URL}/api/admin/activity`)
    return response.data
}

export const disableUser = async (id: string) => {
    const response = await api.post(`${API_URL}/api/admin/toggle-disable-user/${id}`)
    return response.data
}
