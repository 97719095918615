import { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { API_URL } from '../api/urls'

export interface Movie {
    title: string
    year: string
    lastModified: number
    files: { name: string; size: number; lastModified: number }[]
    subtitles: any[]
}

const ITEMS_PER_PAGE = 15

export const useAdminMovies = () => {
    const [movies, setMovies] = useState<Movie[]>([])
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [selectedMovie, setSelectedMovie] = useState<Movie | null>(null)

    const toast = useToast()

    const fetchMovies = async (page: number, limit: number) => {
        setLoading(true)
        try {
            const res = await axios.get(`${API_URL}/api/movies`, {
                params: { username: 'fedmilo', page, limit }
            })

            const sorted = (res.data.movies || []).sort(
                (a: Movie, b: Movie) => parseInt(b.year) - parseInt(a.year)
            )

            setMovies(sorted)
            setTotalPages(res.data.totalPages || 1)
        } catch {
            toast({
                title: 'Error al cargar películas',
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        } finally {
            setLoading(false)
        }
    }

    const openDelete = (movie: Movie) => {
        setSelectedMovie(movie)
        setIsDeleteOpen(true)
    }

    const confirmDelete = async () => {
        if (!selectedMovie) return

        try {
            await axios.delete(`${API_URL}/api/backblaze/movie`, {
                headers: {
                    'x-api-key': '7906ERcQtUu9t',
                    'Content-Type': 'application/json'
                },
                data: {
                    title: selectedMovie.title,
                    year: selectedMovie.year,
                    dryRun: false
                }
            })

            toast({
                title: 'Película eliminada correctamente.',
                status: 'success',
                duration: 3000,
                isClosable: true
            })

            setMovies(prev => prev.filter(m => m.title !== selectedMovie.title))
        } catch {
            toast({
                title: 'Error al eliminar película',
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        } finally {
            setIsDeleteOpen(false)
            setSelectedMovie(null)
        }
    }

    useEffect(() => {
        fetchMovies(currentPage, ITEMS_PER_PAGE)
    }, [currentPage])

    return {
        movies,
        loading,
        currentPage,
        totalPages,
        setCurrentPage,
        isDeleteOpen,
        setIsDeleteOpen,
        selectedMovie,
        openDelete,
        confirmDelete
    }
}
