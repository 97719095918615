import { StackProps, useBreakpointValue, useTheme } from '@chakra-ui/react'

export const useAditionalControlsStyles = () => {
    const theme = useTheme()
    const fontSize = useBreakpointValue({ base: '20px', md: '30px' })

    return {
        iconSize: useBreakpointValue({ base: '20px', md: '30px' }),
        spacingSize: useBreakpointValue({ base: 3, md: 5 }),
        paddingX: useBreakpointValue({ base: 2, md: 3 }),
        hStackProps: {
            position: 'absolute' as StackProps['position'],
            top: 0,
            right: '10px',
            zIndex: 10,
            spacing: useBreakpointValue({ base: 3, md: 5 }),
            bg: 'transparent',
            backdropFilter: 'blur(6px)',
            px: useBreakpointValue({ base: 2, md: 3 }),
            py: 2,
            borderRadius: '10px'
        } as StackProps,
        iconButtonProps: (isActive: boolean) => {
            return {
                color: isActive ? theme.colors.brand.accent : 'white',
                variant: 'ghost',
                fontSize,
                _hover: { bg: 'rgba(255, 255, 255, 0.2)' }
            }
        }
    }
}
