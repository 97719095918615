import { FaRandom } from 'react-icons/fa'
import {
    ButtonProps,
    MenuListProps,
    BoxProps,
    FlexProps,
    HeadingProps,
    IconButtonProps,
    TextProps,
    useTheme
} from '@chakra-ui/react'
import React from 'react'

interface HeaderStyles {
    boxProps: BoxProps
    headingProps: HeadingProps
}

export const useHeaderStyles = (): HeaderStyles => {
    const theme = useTheme()

    return {
        boxProps: {
            bg: theme.colors.brand.header,
            w: '100%',
            p: 4,
            position: 'sticky',
            top: '0',
            zIndex: '1000',
            boxShadow: 'lg',
            transition: 'all 0.3s ease',
            maxW: '100vw'
        },
        headingProps: {
            as: 'h1',
            size: 'lg',
            color: theme.colors.brand.accent,
            fontWeight: 'bold',
            fontFamily: 'Creepster, sans-serif',
            cursor: 'pointer',
            transition: 'color 0.3s ease'
        }
    }
}

export const flexProps: FlexProps = {
    align: 'center',
    justify: 'space-between',
    maxW: '95%',
    mx: 'auto'
}

export const flexLogoProps: FlexProps = {
    flex: '1',
    align: 'center',
    justify: 'flex-start',
    position: 'relative',
    py: '.5rem'
}

export const flexHamburguerProps: FlexProps = {
    display: { base: 'flex' },
    ml: 'auto'
}

export const flexMenuAndSearchProps: FlexProps = {
    display: { base: 'none', md: 'flex' },
    align: 'center',
    gap: 4,
    flex: '1',
    justify: 'flex-start',
    ml: '1rem'
}

export const flexMenuRightOptionsProps: FlexProps = {
    align: 'center',
    gap: 2,
    ml: 'auto'
}

export const linkFlexProps: FlexProps = {
    align: 'center',
    gap: 6
}

export const iconButtonProps: IconButtonProps = {
    color: 'white',
    bg: 'transparent',
    _hover: { color: 'red.400' },
    fontSize: 'lg',
    'aria-label': ''
}

export const menuButtonProps: ButtonProps = {
    color: 'white',
    fontSize: 'md',
    bg: 'transparent',
    minW: 'max-content',
    _hover: { bg: 'gray.700', color: 'red.400' },
    _expanded: { bg: 'gray.700', color: 'red.500' },
    _focus: { boxShadow: 'none' }
}

export const movieButtonProps: ButtonProps = {
    color: 'white',
    fontSize: 'md',
    variant: 'ghost',
    _hover: { bg: 'gray.700', color: 'red.400' },
    _expanded: { bg: 'gray.700', color: 'red.500' }
}

export const seriesButtonProps: ButtonProps = {
    color: 'white',
    fontSize: 'md',
    variant: 'ghost',
    _hover: { bg: 'gray.700', color: 'red.400' },
    _expanded: { bg: 'gray.700', color: 'red.500' },
    mr: '2rem'
}

export const shuffleButtonProps = (isIconActive: boolean) => ({
    'aria-label': 'Shuffle Movies',
    bg: 'transparent',
    icon: React.createElement(FaRandom),
    color: isIconActive ? 'red.400' : 'white',
    _hover: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
    mr: 2,
    animate: { rotate: isIconActive ? 360 : 0 },
    transition: { duration: 0.25, ease: 'easeInOut' }
})

export const menuListProps: MenuListProps = {
    bg: 'gray.800',
    borderColor: 'gray.600',
    maxH: '250px',
    overflowY: 'auto',
    css: {
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#555',
            borderRadius: '8px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#333'
        }
    }
}

export const textSaluteProps: TextProps = {
    color: 'white',
    fontSize: 'lg',
    display: { base: 'none', md: 'block' },
    mr: { base: 'none', md: '2rem' }
}

export const textUsernameProps: TextProps = {
    as: 'span',
    color: 'red.400',
    fontWeight: 'bold',
    fontSize: 'lg'
}

export const tooltipTextProps: TextProps = {
    display: { base: 'none', md: 'block' },
    color: 'white',
    fontSize: 'lg'
}

export const searchContainerProps: FlexProps = {
    position: 'relative',
    justify: 'flex-end',
    flex: 1,
    maxWidth: '240px',
    ml: 'auto',
    mr: { base: 'none', md: '1.4rem' }
}
