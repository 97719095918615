import { Flex, useTheme } from '@chakra-ui/react'

import { dotSpinner } from 'ldrs'

dotSpinner.register()

const SpinnerCentered = () => {
    const theme = useTheme()

    return (
        <Flex
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            align="center"
            justify="center"
            zIndex={1}
        >
            <l-dot-spinner
                size="40"
                stroke-length="0.15"
                speed="0.9"
                bg-opacity="0.1"
                color={theme.colors.brand.accent}
            ></l-dot-spinner>
        </Flex>
    )
}

export default SpinnerCentered
