import { Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

interface ProgressSliderControlProps {
    progress: number | null
    handleSeekChange: (value: number) => void
    handleSliderHover: (event: React.MouseEvent<HTMLDivElement>, offsetWidth: number) => void
    clearHoverTime: () => void
    hoverTime: number | null
    duration: number
    remainingTime: number
}

const ProgressSliderControl: React.FC<ProgressSliderControlProps> = ({
    progress,
    handleSeekChange,
    handleSliderHover,
    clearHoverTime,
    hoverTime,
    duration,
    remainingTime
}) => (
    <Flex
        position="absolute"
        bottom="10px"
        left="0"
        right="0"
        px={4}
        align="center"
        justify="space-between"
        zIndex={2}
        bg="rgba(0, 0, 0, 0.4)"
        backdropFilter="blur(10px)"
        py={2}
        borderRadius="8px"
    >
        <Slider
            aria-label="Progress slider"
            min={0}
            max={1}
            step={0.01}
            value={progress ?? 0}
            onChange={handleSeekChange}
            onMouseMove={e => handleSliderHover(e, e.currentTarget.offsetWidth)}
            onMouseLeave={clearHoverTime}
            flex="1"
            position="relative"
            maxWidth="100%"
            size="md"
            mx={4}
        >
            {/* Track: gris oscuro */}
            <SliderTrack bg="gray.600">
                <SliderFilledTrack bg="whiteAlpha.900" />
            </SliderTrack>

            {/* Slider Thumb: más minimalista */}
            <SliderThumb
                boxSize={5}
                bg="whiteAlpha.900"
                border="2px solid rgba(255, 255, 255, 0.5)"
                borderRadius="full"
                transition="transform 0.2s ease"
            />

            {/* Mostrar tiempo de hover */}
            {hoverTime !== null && (
                <Text
                    position="absolute"
                    bottom="40px"
                    left={`${(hoverTime / duration) * 100}%`}
                    transform="translateX(-50%)"
                    fontSize="sm"
                    color="white"
                    bg="rgba(0, 0, 0, 0.6)"
                    px={2}
                    py={1}
                    borderRadius="md"
                    fontWeight="medium"
                >
                    {formatTime(hoverTime)}
                </Text>
            )}
        </Slider>

        <Text fontSize="sm" color="whiteAlpha.800" ml={4} fontWeight="medium">
            -{formatTime(remainingTime)}
        </Text>
    </Flex>
)

export default ProgressSliderControl
