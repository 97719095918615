import { ButtonProps, FlexProps, useTheme } from '@chakra-ui/react'

export const usePaginatorStyles = () => {
    const theme = useTheme()

    const containerStyles: FlexProps = {
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '300px',
        zIndex: 1000,
        bg: 'rgba(20, 20, 20, 0.9)',
        p: 4,
        shadow: 'lg',
        justify: 'center',
        align: 'center',
        backdropFilter: 'blur(8px)'
    }

    const buttonStyles: ButtonProps = {
        variant: 'solid',
        bg: 'gray.700',
        color: 'white',
        _hover: {
            bg: theme.colors.brand.accent,
            color: 'white'
        }
    }

    const disabledButtonStyles: ButtonProps = {
        ...buttonStyles,
        bg: 'gray.600',
        isDisabled: true,
        _hover: {}
    }

    return { containerStyles, buttonStyles, disabledButtonStyles }
}
