import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    Text,
    Divider,
    Image,
    Badge,
    Link,
    Icon
} from '@chakra-ui/react'
import MovieVideoPlayer from './MovieVideoPlayer'
import useMovieDetailModal, { MovieDetails } from '../../hooks/useMovieDetailModal'
import { cleanTitle } from '../../utils/cleanMovieTitle'
import MovieRatings from '../Common/MovieRatings'
import { FaPlayCircle } from 'react-icons/fa'
import { useMovieDetailModalStyles } from '../../constants/styles/movieModalStyles'

interface MovieDetailModalProps {
    isOpen: boolean
    onClose: () => void
    onLoaded: () => void
    videoUrl: string | null
    subtitleText: string | null
    movieDetails: MovieDetails
}

const MovieDetailModal = ({
    isOpen,
    onClose,
    onLoaded,
    videoUrl,
    subtitleText,
    movieDetails
}: MovieDetailModalProps) => {
    const { Title, Year, Poster, translatedPlot, trailerUrl } = useMovieDetailModal(
        movieDetails,
        onLoaded
    )

    const {
        modalContentProps,
        modalCloseButtonProps,
        modalBodyProps,
        boxVideoProps,
        badgeTitleProps,
        imageModalProps,
        highlightedTextProps,
        textProps,
        trailerLinkProps
    } = useMovieDetailModalStyles(Poster)

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
            <ModalOverlay />
            <ModalContent {...modalContentProps}>
                <ModalCloseButton {...modalCloseButtonProps} />
                <ModalBody {...modalBodyProps} position="relative" zIndex={1}>
                    <>
                        {/* 🎬 Video Section */}
                        <Box {...boxVideoProps}>
                            {videoUrl && (
                                <MovieVideoPlayer
                                    videoUrl={videoUrl}
                                    subtitleText={subtitleText}
                                    title={Title}
                                />
                            )}
                        </Box>

                        {/* Information Section */}
                        <Box flex="2" p={3}>
                            <Badge {...badgeTitleProps} fontSize="2xl">
                                {cleanTitle(Title || 'Title Not Available')}
                            </Badge>
                            <Divider borderColor="gray.600" mb={4} />
                            <Box display="flex" justifyContent="center" mb={4}>
                                {Poster && (
                                    <Image
                                        {...imageModalProps}
                                        src={Poster}
                                        alt={`${Title} poster`}
                                    />
                                )}
                            </Box>

                            {/* 🔹 **Metadata** */}
                            <Text {...highlightedTextProps}>
                                Año:{' '}
                                <Text as="span" {...textProps}>
                                    {Year}
                                </Text>
                            </Text>
                            <Text {...highlightedTextProps}>
                                Género:{' '}
                                <Text as="span" {...textProps}>
                                    {movieDetails?.Genre}
                                </Text>
                            </Text>
                            <Text {...highlightedTextProps}>
                                Director:{' '}
                                <Text as="span" {...textProps}>
                                    {movieDetails?.Director}
                                </Text>
                            </Text>
                            <Text {...highlightedTextProps}>
                                Reparto:{' '}
                                <Text as="span" {...textProps}>
                                    {movieDetails?.Actors}
                                </Text>
                            </Text>
                            <Text {...highlightedTextProps}>
                                Sinopsis:{' '}
                                <Text as="span" {...textProps}>
                                    {movieDetails?.TranslatedPlot || translatedPlot}
                                </Text>
                            </Text>

                            <MovieRatings ratings={movieDetails?.Ratings} />

                            {trailerUrl && (
                                <Link href={trailerUrl} isExternal {...trailerLinkProps}>
                                    <Icon as={FaPlayCircle} boxSize={6} />
                                    Ver tráiler
                                </Link>
                            )}
                        </Box>
                    </>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default MovieDetailModal
