import { BoxProps, FlexProps } from '@chakra-ui/react'

export const boxProps: BoxProps = {
    position: 'relative',
    bg: 'black',
    borderRadius: 'md',
    overflow: 'hidden',
    width: '100%',
    boxShadow: 'lg',
    height: { base: '30vh', md: '100%' }
}

export const flexControlsProps = (showControls: boolean): FlexProps => ({
    position: 'absolute',
    top: '0',
    left: 0,
    right: 0,
    px: 4,
    py: 2,
    bg: 'rgba(0, 0, 0, 0.8)',
    align: 'center',
    justify: 'flex-start',
    opacity: showControls ? 1 : 0,
    transition: 'opacity 0.5s ease',
    height: '40px',
    maxWidth: '100%'
})
